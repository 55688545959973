<style>
.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 115vh;
  max-width: 100%;
  position: relative;
}
.v-select__selection--comma {
  margin: 7px 0px 7px 9px !important;
  font-weight: 600;
}
.v-toolbar__title {
  white-space: normal !important;
}
</style>
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-app-bar
          dark
          color="#2C1963"
          app
          :height="$vuetify.breakpoint.smAndUp ? '100px' : '143px'"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="3"
                class="px-0 text-center"
                style="display: flex"
              >
                <v-img
                  :max-width="$vuetify.breakpoint.smAndUp ? '75' : '40'"
                  src="@/assets/publicDonationPage.png"
                  :class="$vuetify.breakpoint.smAndUp ? 'mr-2' : 'mr-2 mt-2'"
                />
                <v-toolbar-title style="font-family: LubalGraph Bd BT">
                  <div class="appBarTitle">Welcome to the WizFit Challenge</div>
                </v-toolbar-title>
              </v-col>
            </v-row>
          </v-container>
        </v-app-bar>
      </v-col>
    </v-row>

    <div class="appBarBottomImg">
      <img
        max-width="100%"
        class="imageBarBottom"
        src="@/assets/publicDonationPage/publicDonation1.svg"
      />
    </div>
  <v-row no-gutters class="full-height-row"  style="margin-left: 5%;">
  <!-- Left Half: Campaign Message -->
  <v-col cols="12" md="6" class="full-height-col" >
    <campaign-message-district></campaign-message-district>
  </v-col>
<v-col cols="12" md="1" class="full-height-col" style="padding-top: 0;">
  </v-col>
  <!-- Right Half: Donation Info -->
  <v-col cols="12" md="4" class="full-height-col">
    <donation-info-card-district></donation-info-card-district>
  </v-col>
  </v-row>
  <v-row no-gutters class="full-height-row">
  <!-- Left Half: Campaign Message -->
  <v-col cols="12" md="6" class="full-height-col">
        <intro-video-pdp-district></intro-video-pdp-district>
      </v-col>
</v-row>
    <v-main style="margin-top: -6%;"> </v-main>
    <v-footer color="#2C1963" dark padless>
      <v-card class="py-10" color="#2C1963" width="100%">
        <v-card-text class="px-0">
          <v-container>
            <v-row :class="[$vuetify.breakpoint.smAndUp ? 'pt-5' : '']">
              <v-col
                cols="12"
                sm="4"
                :class="[$vuetify.breakpoint.xsOnly ? 'py-0' : '']"
                style="overflow: hidden"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="10"
                    :class="[$vuetify.breakpoint.xsOnly ? 'py-4' : '']"
                    :style="
                      $vuetify.breakpoint.xsOnly ? 'overflow-y:hidden' : ''
                    "
                  >
                    <div class="HarlemFooterImageDiv">
                      <img
                        src="@/assets/publicDonationPage/wizardprimarylogo.svg"
                        alt=""
                        class="HarlemFooterImage"
                      />
                    </div>
                    <div class="footerText">
                      WizFit Challenge is a initiative by The World Famous
                      Harlem Wizards
                    </div>
                    <div class="hl" v-if="$vuetify.breakpoint.xsOnly"></div>
                  </v-col>
                  <v-col cols="2" v-if="$vuetify.breakpoint.smAndUp">
                    <div class="vl"></div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="4">
                <v-row justify="center">
                  <v-col cols="12" sm="8">
                    <div class="footerLinks mt-8">
                      <div
                        style="
                          font-weight: 700;
                          font-size: 22px;
                          font-family: Roboto Slab;
                          line-height: 30px;
                          letter-spacing: 1px;
                        "
                      >
                        Useful links
                      </div>
                      <div
                        style="border-bottom: 5px solid #ffba00; width: 70px"
                      ></div>
                      <br /><br />
                      <a
                        href="mailto:harlemwizards.com"
                        style="color: white; font-size: 21px"
                      >
                        About us
                      </a>

                      <!--   <div class="borderLinksContent spacing">FAQs</div>-->

                      <div class="borderLinksContent spacing">
                        Privacy policy
                      </div>

                      <div class="borderLinksContent spacing">
                        Terms & Conditions
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="4" class="px-0">
                <v-row justify="center">
                  <v-col
                    cols="12"
                    sm="8"
                    :class="[$vuetify.breakpoint.xsOnly ? 'pl-6' : '']"
                  >
                    <div class="footerLinks mt-8">
                      <div
                        style="
                          font-weight: 700;
                          font-size: 22px;
                          font-family: Roboto Slab;
                          line-height: 30px;
                          letter-spacing: 1px;
                        "
                      >
                        Need Help
                      </div>
                      <div
                        style="border-bottom: 5px solid #ffba00; width: 70px"
                      ></div>
                      <br /><br />
                      <!--   <div class="infoColumn">
                        <img
                          src="@/assets/publicDonationPage/contactLogo.svg"
                          alt=""
                          class="contactImg"
                          :style="
                            $vuetify.breakpoint.xsOnly ? 'max-width:30px;' : ''
                          "
                        />
                        <div
                          style="margin-top: auto; margin-bottom: auto"
                          class="borderLinksContent ml-6"
                        >
                          <a href="tel:(201)-271-3600" style="color: white"
                            >(201)-271-3600</a
                          >
                        </div>
                      </div>-->
                      <div class="infoColumn">
                        <img
                          src="@/assets/publicDonationPage/message.svg"
                          alt=""
                          class="contactImg"
                          :style="
                            $vuetify.breakpoint.xsOnly ? 'max-width:30px;' : ''
                          "
                        />
                        <div
                          style="margin-top: auto; margin-bottom: auto"
                          class="borderLinksContent ml-6"
                        >
                          <a
                            href="mailto:support@harlemwizardsinabox.com"
                            style="color: white"
                          >
                            support@harlemwizardsinabox.com
                          </a>
                        </div>
                      </div>
                      <br />
                      <div class="infoColumn">
                        <img
                          src="@/assets/publicDonationPage/location.svg"
                          alt=""
                          class="contactImg"
                        />
                        <div
                          style="
                            margin-top: auto;
                            margin-bottom: auto;
                            display: flex;
                          "
                          class="borderLinksContent ml-6"
                        >
                          Harlem Wizards Entertainment Basketball 330 Fairfield
                          Rd.
                          <br v-if="$vuetify.breakpoint.xsOnly" />
                          Fairfield, NJ 07004
                          <!--
                        <a href="https://maps.app.goo.gl/eg8sHhDbKGeeuNLP7" style="color: white"
                          >330 Fairfield Rd. Suite 2Fairfield, NJ 07004</a
                        >-->
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card color="#180943" width="100%">
        <v-divider color="#FFBA00"></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" v-if="$vuetify.breakpoint.smAndUp">
                <div class="footerParagraph">
                  Copyright © 2021 Harlem Wizards
                </div>
              </v-col>
              <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
              <v-col
                cols="12"
                sm="4"
                :style="
                  $vuetify.breakpoint.smAndUp
                    ? 'display: flex; flex-direction: row-reverse'
                    : 'display:flex;align-items:center;'
                "
                :class="[$vuetify.breakpoint.xsOnly ? 'px-7 py-0' : '']"
              >
                <a href="https://twitter.com/HarlemWizards">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/twitter.svg"
                    alt=""
                  />
                </a>
                <a href="https://www.facebook.com/TheHarlemWizards">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/facebook.svg"
                    alt=""
                  />
                </a>
                <a href="https://tiktok.com/@harlemwizards">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/tiktok.svg"
                    alt=""
                  />
                </a>
                <a href="https://www.instagram.com/worldfamousharlemwizards/">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/instagram.svg"
                    alt=""
                  />
                </a>
                <a href="https://www.youtube.com/user/HarlemWizards">
                  <img
                    class="footerIcon"
                    src="@/assets/publicDonationPage/youtube.svg"
                    alt=""
                  />
                </a>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="text-center px-4 py-0"
                v-if="$vuetify.breakpoint.xsOnly"
              >
                <div class="footerParagraph">
                  Copyright © 2021 Harlem Wizards
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
// import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "PublicDonationPageLayout",
  components: {
    CampaignMessageDistrict: () =>
      import(
        "@/components/PublicDonationPages/CampaignMessage/CampaignMessageDistrict"
      ),
    DonationInfoCardDistrict: () =>
      import(
        "@/views/PublicDonationPage/PublicDonationInfo/DonationInfoCard/DonationInfoCardDistrict.vue"
      ),
      IntroVideoPdpDistrict:()=>import("@/components/PublicDonationPages/IntroVideoPdp/IntroVideoPdpDistrict")
  },
  data() {
    return {
      loading: true,
      searchQuery: "",
      searchList: [],
      searchLoading: false,
      videoflag: false,
      videowatched: localStorage.getItem("is_watched"),
      isvideo: false,
      count: 1,
      campaign_status: "",
      alertmessagepopupshow: "",
      lang: "en",
      languageList: [
        { name: "English", value: "en" },
        { name: "Spanish", value: "es" },
      ],
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style scoped>
* {
  font-family: Lato;
}
.full-height-row {
 /* height: 40%;  Set your desired height */
  margin-bottom: 0; /* Ensure no margin-bottom exists */
  padding-bottom: 0; /* Remove any padding that might add extra space */
}

.full-height-col {
}
.heading {
  font-size: 40px;
  font-weight: bold;
}

.hLine {
  width: 100%;
  height: 2px;
  background-color: black;
  margin-top: 5px;
}

.paragraphMessage {
  margin-top: 35px;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 34px;
  margin-left: 70px;
}
.appBarTitle {
  font-size: 22px;
  font-weight: 700;
  padding-top: 20px;
}
.v-btn >>> span {
  color: #2c1963;
  font-weight: 700;
  font-size: 17px;
  font-family: Roboto Slab;
}
.footerParagraph {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: white;
  line-height: 108.5%;
  margin-top: 13px;
}
.footerIcon {
  margin-left: 30px;
}
.footerText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 161.5%;
  /* or 45px */
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 40px;
}
.vl {
  border-left: 2px solid #a691e4;
  height: 300px;
  margin-top: 20px;
}
.hl {
  border-top: 2px solid #a691e4;
  width: 100%;
  margin-top: 20px;
}
.footerLinks {
  color: white;
  font-family: Roboto Slab;
}
.borderLinksContent {
  font-size: 20px;
  font-weight: 500;
}
a.link {
  color: white;
}
.spacing {
  margin-top: 28px;
}
.infoColumn {
  display: flex;
}
.searchList-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.searchList-secondryText {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #757575;
}
.student {
  background: rgba(0, 133, 255, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.teacher {
  background: rgba(56, 167, 55, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #38a737 !important;
  text-transform: capitalize;
}
.school {
  background: rgba(45, 45, 45, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.appBarBottomImg {
  margin-top: 110px;
  margin-bottom: 10px;
}
.HarlemFooterImage {
  width: 250px;
}
.switchStudentPage-btn {
  border: 0.5px dashed #ffffff;
  border-radius: 8px;
  background: #341d75;
}
.switchStudentPage-btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 215%;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
.logout-btn span {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 161.5%;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  * {
    /* overflow-x: hidden; */
  }
  .footerIcon {
    width: 30px;
    margin-left: 20px;
  }
  .HarlemFooterImage {
    width: 190px;
  }
  .footerText {
    margin-top: 10px;
    font-size: 15px;
  }
  .borderLinksContent {
    font-size: 15px;
  }
  .contactImg {
    min-width: 40px;
  }
  .appBarTitle {
    font-size: 18px;
    padding-top: 15px;
  }
  .v-btn >>> span {
    color: #2c1963;
    font-weight: 700;
    font-size: 14px;
    font-family: Roboto Slab;
  }
  .appBarBottomImg {
    margin-top: 147px;
    margin-bottom: -6px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
@media (min-width: 1264px) {
  .container {
    max-width: 100%;
  }
}
</style>
