<template>
  <div>
    <v-dialog
      persistent
      v-model="CampaignDialogData.flag"
      max-width="500px"
    >
      <v-card height="580px" style="background-color: #f2f2f2">
        <v-card-title
          class="headline darken-1 px-2"
          style="background-color: #0d47a1; color: white"
        >
          <!-- <v-img src="@/assets/warning.svg"></v-img> -->
          <img
            style="margin-left: 25px"
            height="65px"
            src="@/assets/warning.svg"
          />
          <div class="headingTitle">
            Please provide the below <br />
            details to publish the <br />campaign
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            color="white"
            size="32"
            @click="CloseForm"
            style="
              cursor: pointer;
              margin-right: 0px;
              float: right;
              position: relative;
              bottom: 40px;
            "
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-10">
        
          <v-row class="py-0">
            <v-col cols="12" class="px-4 ">
              <div>
                <p class="paraTitle" style="width: 170px">Basic Information</p>
              </div>
            </v-col>
            <v-row class="px-4">
              <v-col cols="6" class="px-6 pt-0">
                <v-row class="py-0">
                  <v-icon color="green" v-if="CampaignDialogData.campaign_type">mdi-check</v-icon>
                  <p class="paraValue mt-4">Campaign Type</p>
                </v-row>
              </v-col>
              <v-col cols="6" class="pr-6 pt-0" v-if="CampaignDialogData.campaign_type == 'school'">
                <v-row class="py-0">
                  <v-icon color="green" v-if="CampaignDialogData.values.includes('school')">mdi-check</v-icon>
                  <v-icon color="#E13250" v-else>mdi-close</v-icon>
                  <p class="paraValue mt-4">Select School</p>
                </v-row>
              </v-col>
              <v-col cols="6" class="pr-6 pt-0" v-if="CampaignDialogData.campaign_type == 'district'">
                <v-row class="py-0">
                  <v-icon color="green" v-if="CampaignDialogData.values.includes('district')">mdi-check</v-icon>
                  <v-icon color="#E13250" v-else>mdi-close</v-icon>
                  <p class="paraValue mt-4">Select District</p>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-4 pb-4" no-gutters>
              <v-col cols="6" class="px-3 pt-0">
                <v-row class="py-0">
                  <v-icon color="green" v-if="CampaignDialogData.values.includes('host_name')">mdi-check</v-icon>
                  <v-icon color="#E13250" v-else>mdi-close</v-icon>
                  <p class="paraValue mt-4">Host Name</p>
                </v-row>
              </v-col>
              <v-col cols="6" class="px-3 pt-0">
                <v-row class="py-0">
                  <v-icon color="green" v-if="CampaignDialogData.values.includes('host_email')">mdi-check</v-icon>
                  <v-icon color="#E13250" v-else>mdi-close</v-icon>
                  <p class="paraValue mt-4">Host Email</p>
                </v-row>
              </v-col>
            </v-row>
          </v-row>

          <v-row class="py-0">
            <v-col cols="12" class="px-4 py-0">
              <div>
                <p class="paraTitle" style="width: 135px">Public Address</p>
              </div>
            </v-col>
            <v-col cols="6" class="px-6 pt-0">
              <v-row class="py-0">
                <v-icon
                  color="green"
                  v-if="CampaignDialogData.values.includes('public_address')"
                  >mdi-check</v-icon
                >
                <v-icon color="#E13250" v-else>mdi-close</v-icon>
                <p class="paraValue mt-4">Campaign Public Address</p>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="py-0">
            <v-col cols="12" class="px-4">
              <div>
                <p class="paraTitle" style="width: 150px">Timeline/Events</p>
              </div>
            </v-col>
            <v-row>
              <v-col cols="6" class="px-6 py-0">
                <v-row class="py-0 px-4">
                  <v-icon
                    color="green"
                    v-if="CampaignDialogData.values.includes('start_date')"
                    >mdi-check</v-icon
                  >
                  <v-icon color="#E13250" v-else>mdi-close</v-icon>
                  <p class="paraValue mt-4">Event Start Date</p>
                </v-row>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-row class="py-0">
                  <v-icon
                    color="green"
                    v-if="CampaignDialogData.values.includes('kick_off_date')"
                    >mdi-check</v-icon
                  >
                  <v-icon color="#E13250" v-else>mdi-close</v-icon>
                  <p class="paraValue mt-4">Kick Off Date</p>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="px-6 py-0">
                <v-row class="py-0 pl-4">
                  <v-icon
                    color="green"
                    v-if="
                      CampaignDialogData.values.includes('gold_ball_give_away')
                    "
                    >mdi-check</v-icon
                  >
                  <v-icon color="#E13250" v-else>mdi-close</v-icon>
                  <p class="paraValue mt-4">Special giveaway date</p>
                </v-row>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-row class="py-0">
                  <v-icon
                    color="green"
                    v-if="CampaignDialogData.values.includes('graduation_date')"
                    >mdi-check</v-icon
                  >
                  <v-icon color="#E13250" v-else>mdi-close</v-icon>
                  <p class="paraValue mt-4">Graduation celebration date</p>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="px-6 py-0">
                <v-row class="py-0 pl-4">
                  <v-icon
                    color="green"
                    v-if="CampaignDialogData.values.includes('end_date')"
                    >mdi-check</v-icon
                  >
                  <v-icon color="#E13250" v-else>mdi-close</v-icon>
                  <p class="paraValue mt-4">Event end date</p>
                </v-row>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "CampaignPublishValidation",
  props: ["CampaignDialogData"],
  data() {
    return {};
  },
  methods: {
    CloseForm() {
      this.CampaignDialogData.flag = false;
      this.CampaignDialogData.value = [];
      this.CampaignDialogData.campaign_type = "";
    },
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.headingTitle {
  margin-left: 45px;
  width: 300px;
  font-family: Roboto Slab;
  font-size: 22px;
  font-weight: 600;
}
.paraTitle {
  font-size: 20px;
  border-bottom: 2px solid #2c1963;
  color: #2c1963;
}
.paraValue {
  color: #000000;
  margin-bottom: 18px;
}
</style>
