<style>
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #7253cf;
  color: #fff;
}

.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  height: 2.5em;
  width: 100%;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
.vue__time-picker {
  width: 100%;
}
.form-background {
  background-image: url('~@/assets/thingsToDo/bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 10px;
}
</style>
<template>
  <div class="px-7 py-6 ">
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
        ><v-overlay></v-overlay
      ></v-progress-circular>
    </div>

    <v-card class="form-background">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-card-text>
            <v-form ref="schoolForm" style="margin-top: -40px">
              <div class="my-8 text-center">
                <div class="d-flex" style="justify-content: space-evenly">
                  <div style="text-align-last: center;">
                    <img
                      style="cursor: pointer;"
                      class="coverImg"
                      src="@/assets/thingsToDo/mediacredential33.jpeg"
                    />
                    
                  </div>
                </div>
                <h1 style="color: rgb(56, 34, 122);font-family: 'Roboto';
    font-weight: 900;">
                  2024 HW MEDIA CREDENTIALS
                </h1>
                <h3 style="color: rgb(56, 34, 122)">
                 Please use this form to submit media credential requests for The FINAL SCORE
                </h3>
                <h3 style="color: rgb(56, 34, 122)">
                Confirmation emails, along with pertinent game day details (parking, credential 
                </h3>
                <h3 style="color: rgb(56, 34, 122)">
                 pick up, etc.) will be sent 48 hours prior to the start time of the game.
                </h3>
                <h3 style="color: rgb(56, 34, 122)">
                 **Credential Approval Subject to Verification Process. You will receive a 
                </h3>
                  <h3 style="color: rgb(56, 34, 122)">
                  confirmation email if approved**
                   </h3>
              </div>
              <v-row no-gutters justify="center">
                <v-col  class="px-2 col-md-3 col-lg-3">
                  <v-text-field
                    outlined
                    dense
                    v-model="first_name"
                    label="First Name"
                    color="#7253CF"
                    class="formFields"
                    :rules="[rules.required]"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-text-field>
                </v-col>
                <v-col  class="px-2 col-md-3 col-lg-3">
                  <v-text-field
                    outlined
                    dense
                    v-model="last_name"
                    label="Last Name"
                    color="#7253CF"
                    class="formFields"
                    :rules="[rules.required]"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col  class="px-2 col-md-6 col-lg-6">
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.required, rules.email]"
                    v-model="email"
                    label="Email"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  ></v-text-field>
                </v-col>
              </v-row>
            
              <v-row no-gutters justify="center">
                <v-col  class="px-2 col-md-6 col-lg-6" >
                  <v-text-field
                    outlined
                    dense
                       :rules="[rules.required]"
                    v-model="phone"
                    type="number"
                    label="Phone Number"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col  class="px-2 col-md-6 col-lg-6">
                  <v-autocomplete
                    outlined
                    dense
                    :rules="[rules.required]"
                    v-model="credential_type"
                    :items="eventNameOptions"
                    label="Credential Type"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters justify="center">
                <v-col  class="px-2 col-md-6 col-lg-6">
                  <v-textarea
                    outlined
                    dense
                    v-model="organization"
                    :rules="[rules.required]"
                    label="Organization"
                    color="#7253CF"
                    class="formFields"
                    row-height="8"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col  class="px-2 col-md-6 col-lg-6">
                  <v-text-field
                    outlined
                    dense
                    v-model="title"
                    :rules="[rules.required]"
                    label="Title"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                    :readonly="type === 'view'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
                <v-row no-gutters justify="center" >
              <v-col  class="px-2 col-md-6 col-lg-6"><v-checkbox true-value=true label="Sign up to receive updates for this event and future events via email."
                false-value=false v-model="updates_subscription"></v-checkbox></v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-btn
                  class="submit-btn"
                  :loading="formLoading"
                  dark
                  color="#38227A"
                  @click="submitSchoolForm"
                  >Submit</v-btn
                >
                <br />
              </v-row>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import RULES from "@/common/fieldRules";
import {
  API_EMAIL_MEDIA_POST,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "AddEditViewSchoolModal",
  props: ["DistrictFormData"],
  data() {
    return {
      eventNameOptions: ["PhotoGrapher", "VideoGrapher", "Media"],
      first_name:"",
      last_name:"",
      email:"",
      updates_subscription:"",
      phone:"",
      credential_type:"",
      organization:"",
      title:"",
      rules: RULES,
      loading: false,
      formLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "schoolManagement/getShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewSchoolModal({ show: value });
      },
    },
    type() {
      return this.$store.state.schoolManagement.addEditViewModal.type;
    },
    districtID() {
      return this.$store.state.schoolManagement.addEditViewModal.districtID;
    },
    toastMessage() {
      return "Thank You";
    },
    dialogTitle() {
      return "Add new School";
    },
    formattedPreregistrationDate: {
      get() {
        if (this.pre_registration_date) {
          return moment(this.pre_registration_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedgoldenballDate: {
      get() {
        if (this.golden_ball_date) {
          return moment(this.golden_ball_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedTrainingDate: {
      get() {
        if (this.teacher_training_date) {
          return moment(this.teacher_training_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },
  watch: {
    /**
     * Watch modal show hide
     */
    show(value) {
      if (value) {
        // Modal open callback
        setTimeout(() => {
          this.modalOpenCallback();
        }, 100);
      } else {
        // Modal close callback
        this.modalCloseCallback();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewSchoolModal: "schoolManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    modalOpenCallback() {
      this.loading = true;
    },
    modalCloseCallback() {
   this.first_name="";
      this.last_name="";
      this.email="";
      this.updates_subscription="";
      this.phone="";
      this.credential_type="";
      this.organization="";
      this.title="";
    },
    submitSchoolForm() {
      if (this.$refs.schoolForm.validate()) {
        const self = this;
        self.formLoading = true;
        // console.log("Form validated");

        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);
          self.formLoading = false;
          this.$emit("reload");
         location.reload();
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };

        const failureHandler = (res) => {
          // console.log("Failure ", res);
          this.showToast({
            message: res,
            color: "e",
          });
          self.formLoading = false;
        };

        let formData = new FormData();
        formData.append("first_name", this.first_name);
        formData.append("last_name", this.last_name);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("updates_subscription", this.updates_subscription);
        formData.append("title", this.title);
        formData.append("organization", this.organization);
        formData.append("credential_type", this.credential_type);
        Axios.request_POST(
          API_EMAIL_MEDIA_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
  },
  mounted() {
  },
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 315%;
  color: #ffffff;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.cardActions {
  position: sticky;
  bottom: 0px;
  width: 500px;
  background-color: white;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}

.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-left: 13px;
}

.file-input-col {
  margin-left: 13px;
}
img.logo {
  margin-left: 20px;
  max-height: 100px;
  max-width: 200px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .v-card__title {
    width: 100%;
  }
  .cardActions {
    width: 100%;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media (min-width: 1281px) {
  .h1, h1 {
    font-size: 60px !important;
  }
}
@media (max-width: 1281px) {
  .h1, h1 {
    font-size:36px !important;
  }
}
@media (min-width: 1281px) {
  .coverImg {
   width:80% !important
  }
}
@media (max-width: 1281px) {
  .coverImg {
   width:100% !important
  }
}
.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 1px;
}

</style>
