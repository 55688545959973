<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <div
      class="Publicdonation16d781947Svg"
      style="
        flex: 1 1 0;
        align-self: stretch;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        width: 100%;
      "
    >
      <img
        class="Rectangle"
        style="width: 100%"
        src="@/assets/publicDonationPage/wizfitbanner2.svg"
      />
    </div>
    <br>
    <br>
  <v-row v-if="$vuetify.breakpoint.smAndUp">
    <!-- Left Column for Search Section -->
    <v-col cols="7"> <!-- 60% of the width -->
     <span style="font-size: 30px; font-weight: 500; color: #2c1963;margin: 10px;">Search Your School Here</span>
     <br>
     <br>
     <div class="row" style="margin:-5px;">
     <div class="col-md-6">
      <div style="display: flex; align-items: center;">
        <br /><br />
        <v-text-field
          hide-details=""
          outlined
          :disabled="loading"
          height="43px"
          dense
          v-model="search"
          label="Search by City, State, School Name"
          append-icon="mdi-magnify"
          type="text"
          color="#38227A"
          class="search-input"
          style="margin: 5px"
        >
        </v-text-field>
        <v-btn
          class="rounded-lg dialogAction-btnText px-15"
          height="43px"
          dark
          color="#38227A"
          @click="getCampaignManagementData"
          :loading="loading"
          v-if="this.search!=''"
        >
          <span>Search</span>
        </v-btn>
        <v-btn
          class="rounded-lg dialogAction-btnText px-15"
          height="43px"
          dark
          color="#38227A"
          v-else
          @click="reload"
        >
          <span>Search</span>
        </v-btn>
      </div>
      </div>
      </div>
      <div class="flex-container">
     
          <div v-for="item in schoolList" :key="item.id" class="flex-item">
            <div class="Frame51 ">
              <div class="Frame31">
                <div class="Frame30">
                  <div class="Frame29">
                    <div class="Frame28">
                      <img
                        class="DivVImageImage"
                        style="width: 75px; height: 74.08px"
                        :src="item.logo_url"
                      />
                    </div>

                    <div class="school-button-container">
                      <span style="font-size: 18px; font-weight: 600"
                        >{{ item.city_name }}, {{ item.state_name }}</span
                      >
                      <br />
                      <div class="StMaryHighSchool">
                        {{ item.school_name }}
                      </div>
                    </div>
                    <div
                      class="Button"
                      style="
                        padding-top: 16px;
                        padding-bottom: 15px;
                        padding-left: 28px;
                        padding-right: 28px;
                        background: #cc0000;
                        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
                        border-radius: 6px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                      @click="viewPublicDonationPageHandler(item)"
                    >
                      <div
                        class="RegisterNow"
                        style="
                          text-align: center;
                          color: white;
                          font-size: 18px;
                          font-family: Roboto Slab;
                          font-weight: 600;
                          text-transform: capitalize;
                          word-wrap: break-word;
                          cursor: pointer;
                        "
                      >
                        Register Now
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </v-col>
    <!-- Right Column for Video Section -->
    <v-col cols="5"> <!-- 40% of the width -->
      <div class="Publicdonation16d781947Svg" style=" align-items: center; justify-content: center; height: 100%;">
        <video-section></video-section>
      </div>
    </v-col>
  </v-row>
 <v-row v-if="$vuetify.breakpoint.xsOnly">
   <v-col cols="12"> <!-- 40% of the width -->
      <div class="Publicdonation16d781947Svg" style="display: flex; align-items: center; justify-content: center; height: 100%;">
        <video-section></video-section>
      </div>
    </v-col>
    <!-- Left Column for Search Section -->
    <v-col cols="12"> <!-- 60% of the width -->
     <span style="font-size: 15px; font-weight: 500; color: #2c1963">Search Your School Here</span>
     <br>
     <br>
     <div class="row" style="margin:-18px;">
     <div class="col-md-10">
      <div style="display: flex; align-items: center;">
        <br /><br />
        <v-text-field
          hide-details=""
          outlined
          :disabled="loading"
          height="43px"
          dense
          v-model="search"
          label="Search by City, State, School Name"
          append-icon="mdi-magnify"
          type="text"
          color="#38227A"
          class="search-input"
          style="margin: 5px"
        >
        </v-text-field>
        <v-btn
          class="rounded-lg dialogAction-btnText px-15"
          height="43px"
            width="1px"
          dark
          color="#38227A"
          @click="getCampaignManagementData"
          :loading="loading"
          v-if="this.search!=''"
        >
          <span>Search</span>
        </v-btn>
        <v-btn
          class="rounded-lg dialogAction-btnText px-15"
          height="43px"
              width="1px"
          dark
          color="#38227A"
          v-else
          @click="reload"
        >
          <span>Search</span>
        </v-btn>
      </div>
      </div>
      </div>
         <div class="flex-container" style=" display: inline;">
     
          <div v-for="item in schoolList" :key="item.id" class="flex-item">
            <div class="Frame51">
              <div class="Frame31">
                <div class="Frame30">
                  <div class="Frame29">
                    <div class="Frame28">
                      <img
                        class="DivVImageImage"
                        style="width: 75px; height: 74.08px"
                        :src="item.logo_url"
                      />
                    </div>

                    <div class="school-button-container">
                      <span style="font-size: 18px; font-weight: 600"
                        >{{ item.city_name }}, {{ item.state_name }}</span
                      >
                      <br />
                      <div class="StMaryHighSchool">
                        {{ item.school_name }}
                      </div>
                    </div>
                    <div
                      class="Button"
                      style="
                        padding-top: 16px;
                        padding-bottom: 15px;
                        padding-left: 28px;
                        padding-right: 28px;
                        background: #cc0000;
                        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
                        border-radius: 6px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        display: inline-flex;
                      "
                      @click="viewPublicDonationPageHandler(item)"
                    >
                      <div
                        class="RegisterNow"
                        style="
                          text-align: center;
                          color: white;
                          font-size: 18px;
                          font-family: Roboto Slab;
                          font-weight: 600;
                          text-transform: capitalize;
                          word-wrap: break-word;
                          cursor: pointer;
                        "
                      >
                        Register Now
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </v-col>

    <!-- Right Column for Video Section -->
  
  </v-row>
    <div>
      <template>
        
      </template>
    </div>
  </div>
</template>
<script>
import { API_ADMIN_GET_CAMPAIGN_SCHOOL_LIST } from "@/constants/APIUrls";
/*import {
  CAMPAIGN_ID,
} from "@/constants/APIKeys";
import { ROUTER_URL } from "../../../constants/urls";**/
import Helper from "@/helper";
import Axios from "@/api/BaseAxios";
export default {
  name: "CampaignManagement",
  components:{ VideoSection: () =>
      import("@/components/PublicDonationPages/VideoSection/VideoSectionWizfit.vue"), },
  data() {
    return {
      loading: false,
      items: [],
      schoolList: [],
      adminAccess: localStorage.getItem("adminAccess"),
      search: "",
    };
  },

  methods: {
    getCampaignManagementData() {
      const self = this;
      self.loading = true;
      const successHandler = (res) => {
        self.schoolList = res.data.school_list;
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      let formData = {};
      formData["search"] = this.search;
      console.log(formData);
      Axios.request_GET(
        API_ADMIN_GET_CAMPAIGN_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    viewPublicDonationPageHandler(item) {
      console.log("in scooolll");
      const { sub_domain } = item;
      console.log("in sub_domain", item.sub_domain);

      Helper.openPublicDonationPage(sub_domain);
    },
    reload(){
    location.reload();}
  },
  mounted() {
  this.getCampaignManagementData()},
};
</script>

<style>
.Frame31 {
  width: 100%;
  padding: 20px;
  background: #e7e5ed;
  border-radius: 7px;
  overflow: hidden;
  height: 100%;
  text-align: center;
}

.Frame51 {
  width: 100%;
  top: 10px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}
.Frame30 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: inline-flex;
}
.Frame29 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 27px;
  display: flex;
}
. Frame28 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}
.school-button-container {
  justify-content: space-between; /* Add space between the school name and the button */
}
.StMaryHighSchool {
  text-align: center;
  color: #1d1d1d;
  font-size: 18px;
  font-family: Lato;
  font-weight: 600;
  line-height: 25.76px;
  word-wrap: break-word;
}
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
</style>
<style scoped>
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.theader {
  font-weight: 400;
  font-size: 13px;
  padding: 12px;
  text-align: center;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
  border-radius: 0px;
  min-width: 1500px;
}

.table-div {
  overflow-x: scroll;
}
.v-btn {
  font-family: Lato;
  font-weight: 600;
}
.v-input {
  font-family: Lato;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 0 0 25%; /* Four items per row */
  padding: 10px; /* Adjust as needed */
  box-sizing: border-box; /* Ensure padding doesn't affect item width */
}
</style>
