<style>
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #7253cf;
  color: #fff;
}

.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  height: 2.5em;
  width: 100%;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
.vue__time-picker {
  width: 100%;
}
@media (max-width: 1281px) {
  .col {
    flex-basis: unset;
  }
}
</style>
<template>
  <div class="px-7 py-6" style="background-color: #e5e5e5">
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
        ><v-overlay></v-overlay
      ></v-progress-circular>
    </div>
    <v-card v-if="!loading">
      <v-row class="px-8">
        <v-col cols="12" sm="12" md="12">
          <v-card-text>
            <v-form lazy-validation ref="newCampaignForm">
              <p class="form-heading">Basic Information</p>
              <hr style="margin-top: -17px" />
              <br />
              <v-row>
                <v-col class="input" cols="12" md="12">
               <v-autocomplete
                    v-model="lang"
                    :items="languageList"
                    :disabled="formLoading"
                    item-text="name"
                    item-value="value"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="Select Language"
                    @change="getCampaignDetail()"
                  ></v-autocomplete>
                  </v-col>
              </v-row>
              <v-row>
                <v-col style="height: 80px" cols="12" sm="6" md="6">
                  <p
                    style="
                      color: rgba(0, 0, 0, 0.6);
                      font-size: 17px;
                      margin-bottom: -8px;
                    "
                  >
                    Campaign type
                  </p>
                  <v-radio-group
                    class="radio-group"
                    small
                    v-model="formData[$API_KEYS.CAMPAIGN_TYPE]"
                    row
                  >
                    <v-radio
                      :disabled="formLoading"
                      ref="editButton"
                      color="#2C1963"
                      label="School"
                      value="school"
                    ></v-radio>
                    <v-radio
                      :disabled="formLoading"
                      color="#2C1963"
                      label="District"
                      value="district"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="py-0 pt-4 d-flex" style="margin-top: 10px;">
                  <div
                    style="color: #2c1963; cursor: pointer; font-weight: 600"
                    @click="
                      toggleAddEditViewSchoolModal({
                        show: true,
                        type: 'add',
                      })
                    "
                  >
                    <v-icon color="#2C1963">mdi-plus</v-icon>
                    Add school
                  </div>
                </v-col>
              </v-row>
                 <v-row  v-if="formData.campaign_type == 'district'">
               <v-col class="py-0">
                <!--  <v-text-field
                    v-model="formData.district"
                    :disabled="formLoading"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="District"
                  ></v-text-field>-->
                   <v-autocomplete
                    v-model="formData.district"
                    :items="formData.districtList"
                    :disabled="formLoading"
                    item-value="id"
                    item-text="district_name"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="Select District"
                    @change="getSchoolList"
                  ></v-autocomplete>
                </v-col>
                 </v-row>
              <v-row>
                <v-col class="py-0" >
                  <v-autocomplete
                    v-model="formData.school"
                    :items="formData.schoolList"
                    :disabled="formLoading"
                    item-text="school_name"
                    item-value="id"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="Select School"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-autocomplete
                    v-model="formData.salesRep"
                    :items="formData.salesRepList"
                    :disabled="formLoading"
                    item-value="id"
                    item-text="name"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="Select Sales Representative"
                  ></v-autocomplete>
                </v-col>
                <v-col class="py-0">
                  <v-autocomplete
                    v-model="formData.eventSupport"
                    :items="formData.eventSupportList"
                    :disabled="formLoading"
                    item-value="id"
                    item-text="name"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="Select Event Support"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input">
                  <v-text-field
                    v-model="formData.hostName"
                    :disabled="formLoading"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="Host Name"
                  ></v-text-field>
                </v-col>
                <v-col class="input">
                  <v-text-field
                    v-model="formData.hostEmail"
                    :disabled="formLoading"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="Host Email"
                  ></v-text-field>
                </v-col>
              </v-row>
           
              <v-row>
                <v-col style="height: 80px" cols="12" sm="12" md="12">
                  <p
                    style="
                      color: rgba(0, 0, 0, 0.6);
                      font-size: 17px;
                      margin-bottom: -8px;
                    "
                  >
                    Select Payout Mode
                  </p>
                  <v-radio-group
                    class="radio-group"
                    small
                    v-model="formData.payoutMode"
                    row
                  >
                    <v-radio
                      :disabled="formLoading || type === 'edit'"
                      color="#2C1963"
                      :label="
                        formData.campaign_type == 'district'
                          ? 'Payout to District & Individual Schools'
                          : 'Payout to Individual Schools'
                      "
                      :value="
                        formData.campaign_type == 'district' ? 'both' : 'school'
                      "
                    ></v-radio>
                    <v-radio
                      v-if="formData.campaign_type == 'district'"
                      :disabled="formLoading || type === 'edit'"
                      color="#2C1963"
                      label="Payout to District"
                      value="district"
                    ></v-radio>
                    <v-radio
                      label="Payout to Harlem Wizard"
                      value="Harlem Wizard"
                      color="#2C1963"
                      :disabled="formLoading || type === 'edit'"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row class="d-none d-sm-flex">
                <v-col class="input" cols="12" style="margin-top: 30px">
                  <v-text-field
                    outlined
                    color="#2C1963"
                    :disabled="formLoading"
                    @change="getValidateSubDomain"
                    class="formFields"
                    :rules="[DomainValid]"
                    dense
                    v-model="formData.subDomain"
                    label="Campaign Public Address"
                    suffix=".harlemwizardsinabox.com"
                  ></v-text-field>
                  <p style="margin-top: -10px; margin-left: 5px">
                    Ex:issaquah.harlemwizardsinabox.com
                  </p>
                </v-col>
                <div class="pt-5" style="position: absolute; left: 780px">
                  <v-icon color="green" v-if="domainCheckOff"
                    >mdi-check-circle</v-icon
                  >
                </div>
              </v-row>
              <v-row class="d-flex d-sm-none">
                <v-col class="input" cols="12" style="margin-top: 70px">
                  <v-text-field
                    outlined
                    color="#2C1963"
                    dense
                    :disabled="formLoading"
                    class="formFields"
                    v-model="formData.subDomain"
                    label="Campaign Public Address"
                  ></v-text-field>
                  <p
                    style="
                      margin-top: -20px;
                      margin-left: 5px;
                      font-family: Lato;
                    "
                  >
                    Ex:issaquah@harlemwizardsinabox.com
                  </p>
                </v-col>
              </v-row>
              <v-row class="pt-6">
                <v-col
                  ><p class="form-heading">Default Goals</p>
                  <hr style="margin-top: -17px" />
                  <br
                /></v-col>
              </v-row>
              <v-row>
                <v-col
                  class="input"
                  v-if="formData.campaign_type == 'district'"
                  cols="6"
                >
                  <v-text-field
                    outlined
                    dense
                    :disabled="formLoading"
                    class="formFields"
                    label="District Goal"
                    color="#2C1963"
                    type="number"
                    v-model="formData.districtGoal"
                  ></v-text-field>
                </v-col>
                <v-col class="input" cols="6">
                  <v-text-field
                    outlined
                    dense
                    class="formFields"
                    :disabled="formLoading"
                    label="School Goal"
                    color="#2C1963"
                    type="number"
                    v-model="formData.schoolGoal"
                  ></v-text-field>
                </v-col>
                <v-col class="input" cols="6">
                  <v-text-field
                    outlined
                    dense
                    class="formFields"
                    :disabled="formLoading"
                    label="Teacher Goal"
                    color="#2C1963"
                    type="number"
                    v-model="formData.teacherGoal"
                  ></v-text-field>
                </v-col>
                <v-col class="input" cols="6">
                  <v-text-field
                    outlined
                    dense
                    class="formFields"
                    :disabled="formLoading"
                    label="Student Goal"
                    type="number"
                    color="#2C1963"
                    v-model="formData.studentGoal"
                  ></v-text-field>
                </v-col>
                <v-col class="input" cols="6">
                  <v-text-field
                    outlined
                    dense
                    class="formFields"
                    :disabled="formLoading"
                    label="Class Room Goal"
                    type="number"
                    color="#2C1963"
                    v-model="classroomGoal"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    dense
                    v-model="schoolGoalDescription"
                    label="School Goal"
                    color="#7253CF"
                    class="formFields"
                    row-height="14"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col
                  ><p class="form-heading">Timeline/Events</p>
                  <hr style="margin-top: -17px" />
                  <br
                /></v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 sm="4" class="input">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-text-field
                        v-model="formatedStartDate"
                        label="Planning Meeting"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="formLoading"
                        outlined
                        class="formFields"
                        dense
                        color="#2C1963"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.eventStartDate"
                      no-title
                      :format="(date) => moment(date).format('MM-DD-YYYY')"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col col-md-2 class="pr-2">
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="start_date_start_time"
                    placeholder="Start Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
                <v-col col-md-2 class="pr-2">
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="start_date_end_time"
                    placeholder="End Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-2 class="input">
                  <v-menu
                    ref="menu12"
                    v-model="menu12"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    class="formFields"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formatedPreregistrationDate"
                        :disabled="formLoading"
                        label="Pre-Registration Date"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        outlined
                        v-on="on"
                        dense
                        color="#2C1963"
                        :rules="[rules.required]"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.preRegistrationDate"
                      :min="formData.eventStartDate"
                      no-title
                      :format="(date) => moment(date).format('MM-DD-YYYY')"
                      @input="menu12 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col col-md-2 class="input">
                  <v-autocomplete
                    label="Pre-Registration Presence"
                    outlined
                    dense
                    v-model="pre_registration_presence"
                    :items="preRegistrationOption"
                    class="formFields"
                    color="#7253CF"
                    :disabled="formLoading"
                  ></v-autocomplete>
                </v-col>
                <v-col col-md-2 class="pr-2">
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="pre_registration_start_time"
                    placeholder="Start Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
                <v-col col-md-2 class="pr-2">
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="pre_registration_end_time"
                    placeholder="End Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
                <v-col col-md-2 class="pr-2">
                  <v-autocomplete
                    label="Team"
                    outlined
                    dense
                    v-model="pre_registration_team"
                    :items="teamNameList"
                    class="formFields"
                    item-text="team_name"
                    item-value="id"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col col-md-2 v-if="this.pre_registration_team == null">
                  <v-combobox
                    v-model="pre_registration_players"
                    :items="playerList"
                    label="Player"
                    class="formFields"
                    item-text="name"
                    item-value="id"
                    color="#7253CF"
                    :disabled="formLoading"
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 class="input">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    class="formFields"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formatedKickOffDate"
                        :disabled="formLoading"
                        label="Kick-Off Date"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        outlined
                        v-on="on"
                        dense
                        color="#2C1963"
                        :rules="[rules.required]"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.kickOffDate"
                      :min="formData.eventStartDate"
                      no-title
                      :format="(date) => moment(date).format('MM-DD-YYYY')"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col col-md-2 class="input">
                  <v-autocomplete
                    label="Kick Off Presence"
                    outlined
                    dense
                    v-model="kick_off_presence"
                    :items="kickOffPresenceOption"
                    class="formFields"
                    color="#7253CF"
                    :disabled="formLoading"
                  ></v-autocomplete>
                </v-col>
                <v-col col-md-2 class="pr-2">
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="kick_off_start_time"
                    placeholder="Start Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
                <v-col col-md-2 class="pr-2">
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="kick_off_end_time"
                    placeholder="End Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>

                <v-col col-md-2 class="pr-2">
                  <v-autocomplete
                    label="Team"
                    outlined
                    dense
                    v-model="kick_off_team"
                    :items="teamNameList"
                    class="formFields"
                    item-text="team_name"
                    item-value="id"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col col-md-2 v-if="this.kick_off_team == null">
                  <v-combobox
                    v-model="kick_off_players"
                    :items="playerList"
                    label="Player"
                    class="formFields"
                    item-text="name"
                    item-value="id"
                    color="#7253CF"
                    :disabled="formLoading"
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="px-2">
                  <label>
                    <span> Additional Kick Off </span>
                  </label>
                  <quillEditor
                    v-model="additional_kickoff_assemblies"
                    :options="editorOption"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 class="input;mb-2px">
                  <v-menu
                    ref="menu13"
                    v-model="menu13"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="formLoading"
                        v-model="formatedEblastGoldBallDate"
                        label="Eblast Special Giveaway Date"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        outlined
                        class="formFields"
                        v-on="on"
                        dense
                        color="#2C1963"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.eblastgoldBallGiveawayDate"
                      no-title
                      :format="dateFormat"
                      @input="menu13 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 class="input">
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="formLoading"
                        v-model="formatedGoldBallDate"
                        label="Special Giveaway Date"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        outlined
                        class="formFields"
                        v-on="on"
                        dense
                        color="#2C1963"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.goldBallGiveawayDate"
                      :min="formData.kickOffDate"
                      no-title
                      :format="dateFormat"
                      @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col col-md-2 class="input">
                  <v-autocomplete
                    label="Gold Ball Presence"
                    outlined
                    dense
                    v-model="gold_ball_presence"
                    :items="goldBallPresenceOption"
                    class="formFields"
                    color="#7253CF"
                    :disabled="formLoading"
                  ></v-autocomplete>
                </v-col>
                <v-col col-md-2>
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="gold_ball_start_time"
                    placeholder="Start Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
                <v-col col-md-2>
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="gold_ball_end_time"
                    placeholder="End Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
                <v-col col-md-2 class="pr-2">
                  <v-autocomplete
                    label="Team"
                    outlined
                    dense
                    v-model="gold_ball_team"
                    :items="teamNameList"
                    class="formFields"
                    item-text="team_name"
                    item-value="id"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col col-md-2 v-if="this.gold_ball_team == null">
                  <v-combobox
                    v-model="gold_ball_players"
                    :items="playerList"
                    label="Player"
                    class="formFields"
                    item-text="name"
                    item-value="id"
                    color="#7253CF"
                    :disabled="formLoading"
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 class="input;mb-2px">
                  <v-menu
                    ref="menu14"
                    v-model="menu14"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="formLoading"
                        v-model="formatedEblastGraduationDate"
                        label="Eblast Graduation Date"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        outlined
                        class="formFields"
                        v-on="on"
                        dense
                        color="#2C1963"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.eblastGraduationDate"
                      no-title
                      :format="dateFormat"
                      @input="menu14 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col col-md-4 class="input">
                  <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="formLoading"
                        v-model="formatedGraduationDate"
                        label="Graduation Celebration Date"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        class="formFields"
                        outlined
                        v-on="on"
                        dense
                        color="#2C1963"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.graduationDate"
                      :min="formData.goldBallGiveawayDate"
                      :format="dateFormat"
                      no-title
                      @input="menu4 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col col-md-2 class="input">
                  <v-autocomplete
                    label="Graduation Presence"
                    outlined
                    dense
                    v-model="graduation_presence"
                    :items="graduationPresenceOption"
                    class="formFields"
                    color="#7253CF"
                    :disabled="formLoading"
                  ></v-autocomplete>
                </v-col>
                <v-col col-md-2>
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="graduation_start_time"
                    placeholder="Start Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
                <v-col col-md-2>
                  <vue-timepicker
                    format="hh:mm A"
                    class="formFields"
                    v-model="graduation_end_time"
                    placeholder="End Time"
                    close-on-complete
                  ></vue-timepicker>
                </v-col>
                <v-col col-md-2 class="pr-2">
                  <v-autocomplete
                    label="Team"
                    outlined
                    dense
                    v-model="graduation_team"
                    :items="teamNameList"
                    class="formFields"
                    item-text="team_name"
                    item-value="id"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col col-md-2 v-if="this.graduation_team == null">
                  <v-combobox
                    v-model="graduation_players"
                    :items="playerList"
                    label="Player"
                    class="formFields"
                    item-text="name"
                    item-value="id"
                    color="#7253CF"
                    :disabled="formLoading"
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="px-2">
                  <label>
                    <span> Additional Graduation </span>
                  </label>
                  <quillEditor
                    v-model="additional_graduation_assemblies"
                    :options="editorOption"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="input" col-md-4>
                  <v-menu
                    ref="menu5"
                    v-model="menu5"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formatedEndDate"
                        :disabled="formLoading"
                        label="Event End Date"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        outlined
                        class="formFields"
                        v-on="on"
                        dense
                        color="#2C1963"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.eventEndDate"
                      :min="formData.graduationDate"
                      no-title
                      @input="menu5 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>Notes</label>
                  <quillEditor v-model="notes" :options="editorOption" />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  ><p class="form-heading">Incentives</p>
                  <hr style="margin-top: -17px" />
                  <br
                /></v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <p
                    class="mb-n3"
                    style="color: rgba(0, 0, 0, 0.6); font-size: 17px"
                  >
                    Student Incentive
                  </p>
                  <br />
                  <quillEditor
                    v-model="formData.studentIncentive"
                    :options="quillEditorData.editorOptionS"
                  /><br /><br />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div style="color: rgba(0, 0, 0, 0.6); font-size: 17px">
                    Student Page Message
                  </div>
                  <br />
                  <div>
                    <v-btn
                      text
                      class="s-btn text-capitalize mt-n1 ml-2"
                      @click="autoPopulateTeacherMessage"
                    >
                      <span>Add message</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <quillEditor
                    v-model="formData.teacherIncentive"
                    :options="quillEditorData.editorOptionT"
                  />
                  <br /><br />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="mb-n2">
                    <div style="color: rgba(0, 0, 0, 0.6); font-size: 17px">
                      Message to be displayed on the Campaign page
                    </div>
                    <br />
                    <div>
                      <v-btn
                        text
                        class="s-btn text-capitalize mt-n1 ml-2"
                        @click="autoPopulateCampaignMessage"
                      >
                        <span>add message</span>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div>
                    <quillEditor
                      class="ql-active"
                      v-model="formData.SpecialMessage"
                      :options="quillEditorData.editorOptionsMessage"
                    />
                  </div>

                  <br /><br />
                </v-col>
              </v-row>

          <!--    <v-row v-if="formData.campaign_type == 'district'">
                <v-col>
                  <p class="form-heading">Participating Schools</p>
                  <hr style="margin-top: -17px" />
                  <p class="bulk" @click="route">+Bulk Upload schools</p>
                  <p
                    class="bulk mr-1"
                    @click="
                      toggleAddEditViewSchoolModal({
                        show: true,
                        type: 'add',
                        dID: formData.district,
                      })
                    "
                  >
                    +Add School
                  </p>
                  <br
                /></v-col>
              </v-row>
              <v-row v-if="formData.campaign_type == 'district'">
                <v-col>
                  <v-combobox
                    v-model="formData.participatingSchools"
                    :disabled="formLoading"
                    :items="formData.schoolList"
                    label="Select Participating Schools"
                    multiple
                    outlined
                    class="formFields"
                    item-text="school_name"
                    item-value="id"
                    color="#2C1963"
                    height="100"
                    chips
                  ></v-combobox>
                </v-col>
              </v-row>-->
       
              <v-row>
                <v-col>
                  <p class="form-heading">Images & Video Gallery</p>
                  <hr style="margin-top: -17px" />
                  <br
                /></v-col>
              </v-row>
              <v-row>
                <!-- <v-file-input
                  color="#7253CF"
                  dense
                  class="file-input"
                  id="inputId"
                  style="display: none"
                  v-model="formData.imgFile"
                  @change="uploadImage"
                  :disabled="formLoading"
                  accept="image/*,video/*"
                  capture="user,environment"
                  multiple
                >
                </v-file-input>-->
                <input
                  type="file"
                  multiple
                  style="display: none"
                  id="inputId"
                  ref="inputGallery"
                  accept="image/*,video/*"
                  :disabled="formLoading"
                  @change="uploadImage"
                />
                <v-col
                  style="margin-top: 0; padding-top: 0"
                  cols="6"
                  sm="4"
                  md="4"
                >
                  <div v-if="!imgLoading">
                    <label style="cursor: pointer" for="inputId">
                      <div class="gallery">
                        <v-icon color="#CECECE" class="input-label" size="50"
                          >mdi-cloud-upload-outline</v-icon
                        >
                        <h3
                          style="
                            color: #2c1963;
                            font-size: 19px;
                            margin-top: -5px;
                          "
                        >
                          Upload
                        </h3>
                        <p style="color: #404040; font-size: 16px">
                          images & videos
                        </p>
                      </div>
                    </label>
                  </div>
                  <div class="gallery py-13" v-else>
                    <h3 style="color: #2c1963; font-size: 17px">
                      Uploading {{ fileUploadCount }}/{{ multipleFileLength }}
                    </h3>
                    <p style="color: #404040; font-size: 14px">
                      {{ progressPercentage }}%
                    </p>
                    <div class="px-6" style="margin-top: -15px">
                      <v-progress-linear
                        color="#7253CF"
                        indeterminate
                        class="py-0"
                        height="25"
                      ></v-progress-linear>
                    </div>
                  </div>
                </v-col>
                <v-col
                  style="margin-top: 0; padding-top: 0; position: relative"
                  cols="6"
                  sm="4"
                  md="4"
                  v-for="(item, i) in formData.previewAssests"
                  :key="i"
                >
                  <v-row style="position: absolute; width: 100%" class="py-2">
                    <v-col cols="12" class="py-0 px-0">
                      <div
                        class="pl-4 pr-2 pt-1"
                        style="
                          width: 100%;
                          display: flex;
                          justify-content: space-between;
                        "
                      >
                        <div>
                          <v-img
                            class="mt-1"
                            width="18px"
                            src="@/assets/campaingTitleImagelogo.svg"
                          />
                        </div>

                        <div class="delete-btn mr-1" style="">
                          <v-icon
                            @click="deleteImage(item.id)"
                            size="22"
                            color="red"
                            >mdi-delete
                          </v-icon>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      style="margin-top: 0; height: 140px"
                      cols="12"
                      class="text-center px-0 ma-0 pb-2"
                    >
                      <div v-if="item.type === 'image'" class="previewImages">
                        <img
                          style="max-width: 100%; height: 140px"
                          :src="item.file_url"
                        />
                      </div>
                      <div
                        v-else
                        style="width: 100%; display: flex"
                        class="py-2"
                      >
                        <VueVideoThumbnail
                          :video-src="item.file_url"
                          show-play-button
                          :snapshot-at-duration-percent="70"
                          :height="130"
                          :width="180"
                          background-fill-color="white"
                          :chunks-quantity="10"
                        >
                          <template #snapshot="{ snapshot }">
                            <img
                              v-if="snapshot"
                              :src="snapshot"
                              alt="snapshot"
                            />
                          </template>
                        </VueVideoThumbnail>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: -5px">
                    <v-col cols="12">
                      <v-text-field
                        label="Add Tag Line"
                        color="#2c1963"
                        dense
                        outlined
                        v-model="item.title"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row style="marigin: 0; padding: 0; position: sticky; bottom: 0px">
        <div class="footer-sbt">
          <v-btn
            :disabled="saveDraftbtnLoader"
            :loading="publishbtnLoader"
            class="p-btn"
            @click="submitForm(true)"
            >Publish</v-btn
          >
          <v-btn
            :disabled="publishbtnLoader"
            :loading="saveDraftbtnLoader"
            @click="submitForm(false)"
            class="s-btn"
            v-if="!isPublished"
            >Save as draft</v-btn
          >
        </div>
        <br />
      </v-row>
    </v-card>
    <CampaignPublishValidation
      :CampaignDialogData="CampaignDialogData"
      v-if="CampaignDialogData"
    />
    <school-modal @reload="getSchoolList"></school-modal>
    <district-modal @reload="getDistrictList"></district-modal>
  </div>
</template>
<script>
import RULES from "@/common/fieldRules";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from "moment";
import CampaignPublishValidation from "../CampaignPublishValidation/CampaignPublishValidation.vue";
import { mapActions } from "vuex";
import Helper from "@/helper";
import {
  API_ADMIN_GET_SCHOOL_LIST,
  API_USER_GET_SALES_REP_LIST,
  API_ADMIN_GET_DISTRICT_LIST,
  API_ADMIN_ADD_CAMPAIGN_IMAGE,
  API_ADMIN_CAMPAIGN_MANAGEMENT_POST,
  API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
  API_ADMIN_CAMPAIGN_MANAGEMENT_UPDATE,
  API_ADMIN_DELETE_CAMPAIGN_IMAGE,
  API_ADMIN_GET_DISTRICT_DETAIL,
  API_ADMIN_GET_SCHOOL_DETAIL,
  API_ADMIN_GET_SUBDOMAIN,
  API_ADMIN_GET_VALIDATE_SUBDOMAIN,
  API_MASTER_GET_MESSAGE,
  GET_DEFAULT_GOAL,
  API_ADMIN_TEAM_NAME_LIST,
  API_EVENT_TEAM_PLAYER_LIST_GET,
  API_USER_GET_EVENT_SUPPORT_LIST,
} from "@/constants/APIUrls";
import { ROUTER_URL } from "@/constants/urls";
import { CAMPAIGN_TYPE, ID, HOST_EMAIL, HOST_NAME,DISTRICT,DISTRICT_NAME } from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { SALES_REP, EVENT_SUPPORT } from "@/constants/ModuleKeys";
import VueVideoThumbnail from "vue-video-thumbnail";
export default {
  name: "NewCampaign",
  components: {
    VueTimepicker,
    quillEditor,
    CampaignPublishValidation,
    VueVideoThumbnail,
    SchoolModal: () =>
      import(
        "../../SchoolManagement/AddEditViewSchoolModal/AddEditViewSchoolModal"
      ),
    DistrictModal: () =>
      import(
        "../../DistrictManagement/AddEditViewDistrictModal/AddEditViewDistrictModal"
      ),
  },
  data() {
    return {
      rules: RULES,
      type: "",
      imgLoading: false,
      school: "",
      team: "",
      formData: {
        [CAMPAIGN_TYPE]: "school",
        payoutMode: "school",
        school: null,
        schoolList: [],
        district: null,
        districtList: [],
        salesRep: null,
        salesRepList: [],
        eventSupport: null,
        eventSupportList: [],
        hostName: "",
        hostEmail: "",
        subDomain: "",
        SpecialMessage: "",
        schoolGoal: null,
        districtGoal: null,
        teacherGoal: null,
        studentGoal: null,
        teacherIncentive: "",
        studentIncentive: "",
        eventStartDate: "",
        kickOffDate: "",
        preRegistrationDate: "",
        graduationDate: "",
        eblastgoldBallGiveawayDate: "",
        eblastGraduationDate: "",
        goldBallGiveawayDate: "",
        eventEndDate: "",
        participatingSchools: [],
        publish: false,
        imgFile: {},
        imgIds: [],
        previewAssests: [],
      },
      quillEditorData: {
        editorOptionsMessage: {
          placeholder: "Special Message",
          readOnly: true,
          theme: "snow",
          modules: {
            toolbar: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [({ header: 1 }, { header: 2 })],
              [{ list: "ordered" }, { list: "bullet" }],
              //   [{ script: "sub" }, { script: "super" }],
              //   [{ indent: "-1" }, { indent: "+1" }],

              //   [{ size: ["small", false, "large", "huge"] }],
              //   [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }],
              //   [{ font: [] }],
              [{ align: [] }],
              [{ direction: "rtl" }], // text direction

              ["clean"],
              [
                "link",
                {
                  handlers: {
                    // handlers object will be merged with default handlers object
                    link: function (value) {
                      if (value) {
                        var href = prompt("Enter the URL");
                        this.quill.format("link", href);
                      } else {
                        this.quill.format("link", false);
                      }
                    },
                  },
                },
              ],
            ],
          },
        },
        editorOptionT: {
          placeholder: "Student Page Message",
          readOnly: true,
          theme: "snow",
          modules: {
            toolbar: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              //   [{ script: "sub" }, { script: "super" }],
              //   [{ indent: "-1" }, { indent: "+1" }],
              //   [{ size: ["small", false, "large", "huge"] }],
              //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              //   [{ font: [] }],
              [{ align: [] }],
              [{ direction: "rtl" }], // text direction
              ["clean"],
              [
                "link",
                // {
                //   handlers: {
                //     // handlers object will be merged with default handlers object
                //     "link": function (value) {
                //       if (value) {
                //         var href = prompt("Enter the URL");
                //         this.quill.format("link", href);
                //       } else {
                //         this.quill.format("link", false);
                //       }
                //     },
                //   },
                // },
              ],
            ],
          },
        },
        editorOptionS: {
          placeholder: "Student Incentive",
          readOnly: true,
          theme: "snow",
          modules: {
            toolbar: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              //   [{ script: "sub" }, { script: "super" }],
              //   [{ indent: "-1" }, { indent: "+1" }],
              //   [{ size: ["small", false, "large", "huge"] }],
              //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              //   [{ font: [] }],
              [{ align: [] }],
              [{ direction: "rtl" }], // text direction
              ["clean"],
              ["link"],
            ],
          },
        },
        editorOptionSG: {
          placeholder: "School Goal",
          readOnly: true,
          theme: "snow",
          modules: {
            toolbar: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              //   [{ script: "sub" }, { script: "super" }],
              //   [{ indent: "-1" }, { indent: "+1" }],
              //   [{ size: ["small", false, "large", "huge"] }],
              //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              //   [{ font: [] }],
              [{ align: [] }],
              [{ direction: "rtl" }], // text direction
              ["clean"],
            ],
          },
        },
      },
      CampaignDialogData: {
        flag: false,
        campaign_type: "",
        values: [],
      },
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menu9: false,
      menu10: false,
      menu11: false,
      menu12: false,
      menu13: false,
      menu14: false,
      dateFormat: "MM-DD-YYYY",
      loading: false,
      formLoading: false,
      isPublished: false,
      districtSchoolList: [],
      multipleFileLength: null,
      fileUploadCount: 0,
      DomainValid: true,
      domainCheckOff: false,
      publishbtnLoader: false,
      saveDraftbtnLoader: false,
      defaultGoals: [],
      filterList: [],
      schoolGoalDescription: "",
      classroomGoal: null,
      //formated date varibles
      formatedPreregistrationDate: "",
      formatedKickOffDate: "",
      formatedGoldBallDate: "",
      formatedEblastGoldBallDate: "",
      formatedGraduationDate: "",
      formatedEblastGraduationDate: "",
      formatedEndDate: "",
      pre_registration_start_time: "08:00 AM",
      pre_registration_end_time: "08:00 AM",
      kick_off_start_time: "08:00 AM",
      kick_off_end_time: "08:00 AM",
      start_date_start_time: "08:00 AM",
      start_date_end_time: "08:00 AM",
      pre_registration_presence: "",
      pre_registration_team: "",
      pre_registration_players: "",
      kick_off_presence: "",
      kick_off_team: "",
      kick_off_players: "",
      gold_ball_start_time: "08:00 AM",
      gold_ball_end_time: "08:00 AM",
      gold_ball_presence: "",
      gold_ball_team: "",
      gold_ball_players: "",
      graduation_start_time: "08:00 AM",
      graduation_end_time: "08:00 AM",
      graduation_presence: "",
      graduation_team: "",
      graduation_players: "",
      preRegistrationOption: ["virtual", "live"],
      kickOffPresenceOption: ["virtual", "live"],
      goldBallPresenceOption: ["virtual", "live"],
      graduationPresenceOption: ["virtual", "live"],
      lisssst: [{ id: 1, team_name: "unassigned" }],
      demoTeamList: [this.lisssst + this.teamNameList],
      teamNameList: [],
      notes: "",
      additional_kickoff_assemblies: "",
      additional_graduation_assemblies: "",
      lang:"en",
       languageList: [
        { name: "English", value:"en"},
        { name: "Spanish",value:"es"},
      ],
    };
  },
  created() {},
  computed: {
    progressPercentage() {
      return Math.trunc((this.fileUploadCount / this.multipleFileLength) * 100);
    },

    campaignType() {
      return this.formData.campaign_type;
    },
    preregistartionStartTime: {
      get() {
        if (this.pre_registration_start_time) {
          return Helper.timeFormatter(this.pre_registration_start_time);
        } else {
          return "";
        }
      },
    },
    preregistartionEndTime: {
      get() {
        if (this.pre_registration_end_time) {
          return Helper.timeFormatter(this.pre_registration_end_time);
        } else {
          return "";
        }
      },
    },
    formatedStartDateStartTime: {
      get() {
        if (this.start_date_start_time) {
          return Helper.timeFormatter(this.start_date_start_time);
        } else {
          return "";
        }
      },
    },
    formatedStartDateEndTime: {
      get() {
        if (this.start_date_end_time) {
          return Helper.timeFormatter(this.start_date_end_time);
        } else {
          return "";
        }
      },
    },
    formatedKickOffStartTime: {
      get() {
        if (this.kick_off_start_time) {
          return Helper.timeFormatter(this.kick_off_start_time);
        } else {
          return "";
        }
      },
    },
    formatedKickOffEndTime: {
      get() {
        if (this.kick_off_end_time) {
          return Helper.timeFormatter(this.kick_off_end_time);
        } else {
          return "";
        }
      },
    },
    formatedgoldballStartTime: {
      get() {
        if (this.gold_ball_start_time) {
          return Helper.timeFormatter(this.gold_ball_start_time);
        } else {
          return "";
        }
      },
    },
    formatedgoldballEndTime: {
      get() {
        if (this.gold_ball_end_time) {
          return Helper.timeFormatter(this.gold_ball_end_time);
        } else {
          return "";
        }
      },
    },
    formatedgraduationStartTime: {
      get() {
        if (this.graduation_start_time) {
          return Helper.timeFormatter(this.graduation_start_time);
        } else {
          return "";
        }
      },
    },
    formatedgraduationEndTime: {
      get() {
        if (this.graduation_end_time) {
          return Helper.timeFormatter(this.graduation_end_time);
        } else {
          return "";
        }
      },
    },
  },
  watch: {
    campaignType() {
      // this.resetForm();
      if (this.campaignType == "district") {
        this.getDistrictList();
      } else {
        this.getSchoolList();
      }
    },
  
    "formData.school": function () {
      if (this.formData.school) {
        this.getAutoFillSchoolDetails();
      }
    },
    "formData.eventStartDate": function () {
      if (this.formData.eventStartDate) {
        this.formatedStartDate = moment(this.formData.eventStartDate).format(
          "MM-DD-YYYY"
        );
      }
    },
    "formData.preRegistrationDate": function () {
      if (this.formData.preRegistrationDate) {
        this.formatedPreregistrationDate = moment(
          this.formData.preRegistrationDate
        ).format("MM-DD-YYYY");
      }
      this.formData.preRegistrationDate = "";
    },
    "formData.kickOffDate": function () {
      if (this.formData.kickOffDate && this.type == "add") {
        this.getAutoGeneratedDate(this.formData.kickOffDate);
      }
      if (this.formData.kickOffDate) {
        this.formatedKickOffDate = moment(this.formData.kickOffDate).format(
          "MM-DD-YYYY"
        );
      }
    },
    "formData.goldBallGiveawayDate": function () {
      if (this.formData.goldBallGiveawayDate) {
        this.formatedGoldBallDate = moment(
          this.formData.goldBallGiveawayDate
        ).format("MM-DD-YYYY");
      }
      this.formData.goldBallGiveawayDate = "";
    },
    "formData.graduationDate": function () {
      if (this.formData.graduationDate) {
        this.formatedGraduationDate = moment(
          this.formData.graduationDate
        ).format("MM-DD-YYYY");
      }
      this.formData.graduationDate = "";
    },
    "formData.eblastgoldBallGiveawayDate": function () {
      if (this.formData.eblastgoldBallGiveawayDate) {
        this.formatedEblastGoldBallDate = moment(
          this.formData.eblastgoldBallGiveawayDate
        ).format("MM-DD-YYYY");
      }
      this.formData.eblastgoldBallGiveawayDate = "";
    },
    "formData.eblastGraduationDate": function () {
      if (this.formData.eblastGraduationDate) {
        this.formatedEblastGraduationDate = moment(
          this.formData.eblastGraduationDate
        ).format("MM-DD-YYYY");
      }
      this.formData.eblastGraduationDate = "";
    },
    "formData.eventEndDate": function () {
      if (this.formData.eventEndDate) {
        this.formatedEndDate = moment(this.formData.eventEndDate).format(
          "MM-DD-YYYY"
        );
      }
      this.formData.eventEndDate = "";
    },
  },
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
      toggleAddEditViewSchoolModal: "schoolManagement/toggleModal",
      toggleAddEditViewDistrictModal: "districtManagement/toggleModal",
    }),
    route() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.bulkUploadSchool.name,
      });
    },
    // getDefaultMessage() {
    //   let count = 0;
    //   const successHandler = (res) => {
    //     const data = res.data.special_message_list[0];
    //     if (data.message_for === "campaign") {
    //       this.formData.SpecialMessage = data.message;
    //     } else if (data.message_for === "teacher") {
    //       this.formData.teacherIncentive = data.message;
    //     } else {
    //       this.formData.studentIncentive = data.message;
    //     }
    //     count = count + 1;
    //     // setTimeout(() => {
    //     //   window.scrollTo(0, 0);
    //     // }, 500);
    //     if (count === 3) {
    //       this.loading = false;
    //     }
    //   };
    //   const failureHandler = (res) => {
    //     console.log(res);
    //   };
    //   const paramObj = [
    //     { message_for: "campaign", message_type: "special_message" },
    //     { message_for: "teacher", message_type: "incentive" },
    //     { message_for: "student", message_type: "incentive" },
    //   ];
    //   for (let obj of paramObj) {
    //     let formData = {};
    //     formData["message_for"] = obj.message_for;
    //     formData["message_type"] = obj.message_type;

    //     Axios.request_GET(
    //       API_MASTER_GET_MESSAGE,
    //       formData,
    //       {},
    //       successHandler,
    //       failureHandler,
    //       false
    //     );
    //   }
    // },
    autoPopulateCampaignMessage() {
      let name, goal, incentive, kickoff_date, graduation_date, pto;
      if (this.campaignType === "school") {
        if (this.formData.school) {
          // console.log("school filter");
          let index = this.formData.schoolList.findIndex(
            (item) => item.id === this.formData.school
          );
          name = this.formData.schoolList[index].school_name;
          pto = this.formData.schoolList[index].lead_group;
          goal = this.formData.schoolGoal / 2;
        } else {
          this.showToast({
            message: "Please select school first",
            color: "w",
          });
          return "";
        }
      } else {
        if (this.formData.district) {
          let index = this.formData.districtList.findIndex(
            (item) => item.id === this.formData.district
          );
          name = this.formData.districtList[index].district_name;
          goal = this.formData.districtGoal / 2;
        } else {
          this.showToast({
            message: "Please select district first",
            color: "w",
          });
          return "";
        }
      }
      if (this.formData.studentIncentive) {
        incentive = this.formData.studentIncentive;
      } else {
        this.showToast({
          message: "Please add stuent incentive",
          color: "w",
        });
        return "";
      }
      if (this.formatedKickOffDate) {
        kickoff_date = this.formatedKickOffDate;
      } else {
        this.showToast({
          message: "Please select kickoff date",
          color: "w",
        });
        return "";
      }
      if (this.formatedGraduationDate) {
        graduation_date = this.formatedGraduationDate;
      } else {
        this.showToast({
          message: "Please select graduation date",
          color: "w",
        });
        return "";
      }

      let campaignMessage = `We are excited to announce that the students of ${name} are teaming up with the World Famous 
Harlem Wizards to help us raise funds for the ${pto} with their new WizFit Challenge fundraiser 
and kids experience. During a two-week period starting on ${kickoff_date} our students will watch and participate in an exclusive 
series of six interactive video episodes led by Harlem Wizard stars Swoop and Broadway. They will get fit, learn tricks,
 have fun, earn prizes, and be coached on character by the Wizards.
 <br>
 <br>
We will then conclude this event on ${graduation_date} with a Graduation Celebration where every student will participate and win 
free prizes.
<br>
<br>
On 2-24-23 at Green Brook Middle School, the party continues with the Harlem Wizards Vs the Teachers. The game begins at 7pm and the doors will open at 6pm. Be sure to purchase your tickets for the game in advance. HARLEM WIZARDS BOX OFFICE
<br>
<br>
${incentive}
<br>
<br>
Our goal is to raise $${goal}. Students can request donations from friends and family to support them participating in
 the WizFit Challenge. We're excited to see our school come together for this fundraising program.`;

      this.formData.SpecialMessage = campaignMessage;
      // console.log(
      //   this.formData.schoolList,
      //   "school",
      //   this.campaignType,
      //   this.formData.districtList,
      //   this.formData.studentIncentive
      // );
    },
    autoPopulateTeacherMessage() {
      let goal, incentive, kickoff_date;
      if (this.campaignType === "school") {
        if (this.formData.school) {
          goal = this.formData.schoolGoal / 2;
        } else {
          this.showToast({
            message: "Please select school first",
            color: "w",
          });
          return "";
        }
      } else {
        if (this.formData.district) {
          goal = this.formData.districtGoal / 2;
        } else {
          this.showToast({
            message: "Please select district first",
            color: "w",
          });
          return "";
        }
      }
      if (this.formatedKickOffDate) {
        kickoff_date = this.formatedKickOffDate;
      } else {
        this.showToast({
          message: "Please select kickoff date",
          color: "w",
        });
        return "";
      }
      if (this.formData.studentIncentive) {
        incentive = this.formData.studentIncentive;
      } else {
        this.showToast({
          message: "Please add stuent incentive",
          color: "w",
        });
        return "";
      }
      let teacherMessage = `The World Famous Harlem Wizards are joining my school
     on ${kickoff_date} to help us raise funds with their new
     WizFit Challenge fundraiser and kids experience.
     During these next two-weeks, I will watch and
     participate in an exclusive series of six interactive
    video challenges led by Harlem Wizard stars Swoop and Broadway.
    I will be requesting donations for participating in and completing the WizFit challenge.
   I will get fit, learn tricks, have fun, earn prizes, and be coached on character by the Wizards.
   <br>
   <br>Our goal is to raise $${goal}.${incentive} We're excited to see our school come together for this fundraising program.`;

      this.formData.teacherIncentive = teacherMessage;
    },
    getDefaultMessageCampaign() {
      const self = this;
      const successHandler = (res) => {
        if (res.data.special_message_list[0]) {
          const data = res.data.special_message_list[0];
          self.formData.SpecialMessage = data.message;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {
        self.getIncentiveTeacher();
      };
      let formData = {};
      formData["message_for"] = "campaign";
      formData["message_type"] = "special_message";
     
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getIncentiveTeacher() {
      const self = this;
      const successHandler = (res) => {
        if (res.data.special_message_list[0]) {
          const data = res.data.special_message_list[0];
          self.formData.teacherIncentive = data.message;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {
        self.getIncentiveStudent();
      };
      let formData = {};
      formData["message_for"] = "teacher";
      formData["message_type"] = "incentive";
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getIncentiveStudent() {
      const self = this;
      const successHandler = (res) => {
        if (res.data.special_message_list[0]) {
          const data = res.data.special_message_list[0];
          self.formData.studentIncentive = data.message;
        }
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      let formData = {};
      formData["message_for"] = "student";
      formData["message_type"] = "incentive";
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getDefaultGoals() {
      const self = this;
      const successHandler = (res) => {
        console.log(res.data.default_goal, "default goals");
        self.defaultGoals = res.data.default_goal;
        let index = self.defaultGoals.findIndex(
          (item) => item.title === "teacher_default_goal"
        );
        self.formData.teacherGoal = self.defaultGoals[index].value;
        index = self.defaultGoals.findIndex(
          (item) => item.title === "student_default_goal"
        );
        self.formData.studentGoal = self.defaultGoals[index].value;
        index = self.defaultGoals.findIndex(
          (item) => item.title === "school_default_goal"
        );
        self.formData.schoolGoal = self.defaultGoals[index].value;
        index = self.defaultGoals.findIndex(
          (item) => item.title === "district_default_goal"
        );
        self.formData.districtGoal = self.defaultGoals[index].value;
        index = self.defaultGoals.findIndex(
          (item) => item.title === "class_default_goal"
        );
        self.classroomGoal = self.defaultGoals[index].value;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = function () {
        // self.getDefaultMessage();
        self.getDefaultMessageCampaign();
      };
      let formData = {};
      Axios.request_GET(
        GET_DEFAULT_GOAL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getAutoGeneratedDate(date) {
      // let one_day = 1000 * 60 * 60 * 24;
      // date = new Date(date);
      console.log(date);
      // this.formData.kickOffDate = new Date(date.getTime() + (15 * one_day));
      if (this.type == "add") {
        this.formData.goldBallGiveawayDate = moment(
          this.formData.kickOffDate
        ).add(7, "days");
      } else {
        this.formData.goldBallGiveawayDate = moment(
          this.formData.goldBallGiveawayDate
        );
      }
      if (this.type == "add") {
        this.formData.eblastgoldBallGiveawayDate = moment(
          this.formData.goldBallGiveawayDate
        ).subtract(2, "days");
      } else {
        this.formData.eblastgoldBallGiveawayDate = moment(
          this.formData.eblastgoldBallGiveawayDate
        );
      }
      if (this.type == "add") {
        this.formData.graduationDate = moment(
          this.formData.goldBallGiveawayDate
        ).add(7, "days");
      } else {
        this.formData.graduationDate = moment(this.formData.graduationDate);
      }

      if (this.type == "add") {
        this.formData.eblastGraduationDate = moment(
          this.formData.graduationDate
        ).subtract(2, "days");
      } else {
        this.formData.eblastGraduationDate = moment(
          this.formData.eblastGraduationDate
        );
      }
      if (this.type == "add") {
        this.formData.eventEndDate = moment(this.formData.graduationDate).add(
          7,
          "days"
        );
      } else {
        this.formData.eventEndDate = moment(this.formData.eventEndDate);
      }
    },
    resetForm() {
      // console.log("form reset");
      this.formData.school = null;
      this.formData.district = null;
      this.formData.salesRep = null;
      this.formData.eventSupport = null;
      this.formData.hostName = "";
      this.formData.hostEmail = "";
      this.formData.subDomain = "";
      this.formData.schoolGoal = null;
      this.formData.districtGoal = null;
      this.formData.teacherGoal = null;
      this.formData.studentGoal = null;
      this.domainCheckOff = false;
    },
    /**
     * to get district list */
    getDistrictList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        self.formData.districtList = data.district_list;
      };

      const failureHandler = function (res) {
        const data = res;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getSchoolList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_ADMIN_GET_DISTRICT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },

    /** to get school list */
    getSchoolList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data, "school");
        self.formData.schoolList = data.school_list;
        if (self.formData.district) {
          self.setParticipatingSchool(data.school_list);
          // self.formData.participatingSchools = data.school_list;
        }
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getSalesRepList();
        self.getEventSupportList();
        self.getteamPlayer();
        self.getTeamNameList();
      };

      const formJson = {};
      if (self.formData.campaign_type === "district") {
        formJson["district_id"] = self.formData.district;
      }
      return Axios.request_GET(
        API_ADMIN_GET_SCHOOL_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    setParticipatingSchool(schoolList) {
      const self = this;
      if (this.formData.participatingSchools[0]) {
        // console.log("participating school exists");
        let res = [];
        res = schoolList.filter((el) => {
          return !self.filterList.find((element) => {
            return element.id === el.id;
          });
        });
        self.formData.participatingSchools =
          self.formData.participatingSchools.concat(res);
        // console.log(res, "filtered array list");
      } else {
        this.formData.participatingSchools = schoolList;
        this.filterList = schoolList;
      }
    },

    /**
     * to get sales represantative list */
    getSalesRepList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        self.formData.salesRepList = data.sales_rep_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };
      const finallyHandler = function () {
        if (self.type == "edit") {
          self.getCampaignDetail();
        } else {
          self.getDefaultGoals();
        }
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_SALES_REP_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getEventSupportList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        self.formData.eventSupportList = data.event_support_staff_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };
      const finallyHandler = function () {
        if (self.type == "edit") {
          self.getCampaignDetail();
        } else {
          self.getDefaultGoals();
        }
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_EVENT_SUPPORT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getCampaignDetail() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data);

        self.formData[CAMPAIGN_TYPE] = data.campaign_detail.campaign_type;
        self.formData.school = data.campaign_detail.school;
         self.formData.district = data.campaign_detail.district;
         self.formData.district_name = data.campaign_detail.district_name;
        self.formData.payoutMode = data.campaign_detail.payout_for;
        self.formData.salesRep = data.campaign_detail.sales_rep;
        self.formData.eventSupport = data.campaign_detail.event_support;
        self.formData.hostName = data.campaign_detail.host_name;
        self.formData.hostEmail = data.campaign_detail.host_email;
        self.formData.subDomain = data.campaign_detail.sub_domain;
        self.formData.districtGoal = data.campaign_detail.district_goal;
        self.formData.schoolGoal = data.campaign_detail.school_default_goal;
        self.formData.teacherGoal = data.campaign_detail.teacher_default_goal;
        self.formData.studentGoal = data.campaign_detail.student_default_goal;
        self.formData.SpecialMessage = data.campaign_detail.special_message;
        self.formData.teacherIncentive = data.campaign_detail.teacher_incentive;
        self.formData.studentIncentive = data.campaign_detail.student_incentive;
        self.formData.eventStartDate = data.campaign_detail.start_date;
        self.formData.eventEndDate = data.campaign_detail.end_date;
        self.formData.kickOffDate = data.campaign_detail.kick_off_date;
        self.formData.eblastGraduationDate =
          data.campaign_detail.graduation_eblast_date;
        self.formData.eblastgoldBallGiveawayDate =
          data.campaign_detail.gold_ball_eblast_date;
        self.formData.preRegistrationDate =
          data.campaign_detail.pre_registration_date;
        if (data.campaign_detail.pre_registration_presence) {
          self.pre_registration_presence =
            data.campaign_detail.pre_registration_presence;
        }
        if (data.campaign_detail.kick_off_presence) {
          self.kick_off_presence = data.campaign_detail.kick_off_presence;
        }
        if (data.campaign_detail.gold_ball_presence) {
          self.gold_ball_presence = data.campaign_detail.gold_ball_presence;
        }
        if (data.campaign_detail.graduation_presence) {
          self.graduation_presence = data.campaign_detail.graduation_presence;
        }

        self.pre_registration_team = data.campaign_detail.pre_registration_team;
        self.kick_off_team = data.campaign_detail.kick_off_team;
        self.gold_ball_team = data.campaign_detail.gold_ball_team;
        self.graduation_team = data.campaign_detail.graduation_team;
        self.additional_kickoff_assemblies =
          data.campaign_detail.additional_kickoff_assemblies;
        self.additional_graduation_assemblies =
          data.campaign_detail.additional_graduation_assemblies;
        self.notes = data.campaign_detail.notes;
        if (data.campaign_detail.pre_registration_start_time) {
          self.pre_registration_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.pre_registration_start_time
          );
        }
        if (data.campaign_detail.pre_registration_end_time) {
          self.pre_registration_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.pre_registration_end_time
          );
        }
        if (data.campaign_detail.start_date_start_time) {
          self.start_date_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.start_date_start_time
          );
        }
        if (data.campaign_detail.start_date_end_time) {
          self.start_date_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.start_date_end_time
          );
        }
        if (data.campaign_detail.kick_off_start_time) {
          self.kick_off_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.kick_off_start_time
          );
        }
        if (data.campaign_detail.kick_off_end_time) {
          self.kick_off_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.kick_off_end_time
          );
        }
        if (data.campaign_detail.gold_ball_start_time) {
          self.gold_ball_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.gold_ball_start_time
          );
        }
        if (data.campaign_detail.gold_ball_end_time) {
          self.gold_ball_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.gold_ball_end_time
          );
        }
        if (data.campaign_detail.graduation_start_time) {
          self.graduation_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.graduation_start_time
          );
        }
        if (data.campaign_detail.graduation_end_time) {
          self.graduation_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.graduation_end_time
          );
        }
        //Helper.convertFrom24To12Format(
        // if (data.campaign_detail.kick_off_end_time) {
        //   let timeForm = data.campaign_detail.kick_off_end_time.split(":");
        //   self.kick_off_end_time = `${timeForm[0]}:${timeForm[1]}`;
        //  }
        // self.schoolGoalDescription =
        //   data.campaign_detail.school_goal_description;
        self.formData.graduationDate =
          data.campaign_detail.graduation_celebration_date;
        self.formData.goldBallGiveawayDate =
          data.campaign_detail.gold_ball_giveaway_date;
        // self.formData.participatingSchools = data.school_list;
        self.formData.previewAssests = [];
        self.formData.previewAssests = data.campaign_assests_list;
        self.isPublished = data.campaign_detail.is_published;
        self.classroomGoal = data.campaign_detail.class_goal;

        if (self.formData.campaign_type == "district") {
          let schoolIdList = data.school_list.map(function (obj) {
            return obj.id;
          });
          self.formData.participatingSchools = self.formData.schoolList.filter(
            (k) => schoolIdList.includes(k.id)
          );
        }
        if (data.campaign_detail.pre_registration_player_list != null) {
          let prergistrationIdList =
            data.campaign_detail.pre_registration_player_list.map(function (
              obj
            ) {
              return obj.id;
            });
          self.pre_registration_players = self.playerList.filter((k) =>
            prergistrationIdList.includes(k.id)
          );
        }
        if (data.campaign_detail.kick_off_player_list != null) {
          let kickoffplayerIdList =
            data.campaign_detail.kick_off_player_list.map(function (obj) {
              return obj.id;
            });
          self.kick_off_players = self.playerList.filter((k) =>
            kickoffplayerIdList.includes(k.id)
          );
        }
        if (data.campaign_detail.gold_ball_player_list != null) {
          let goldballplayerIdList =
            data.campaign_detail.gold_ball_player_list.map(function (obj) {
              return obj.id;
            });
          self.gold_ball_players = self.playerList.filter((k) =>
            goldballplayerIdList.includes(k.id)
          );
        }
        if (data.campaign_detail.graduation_player_list != null) {
          let graduationplayerIdList =
            data.campaign_detail.graduation_player_list.map(function (obj) {
              return obj.id;
            });
          self.graduation_players = self.playerList.filter((k) =>
            graduationplayerIdList.includes(k.id)
          );
        }
        //  data.campaign_assests_list.forEach((dt)=>{
        //    let ImgData ={
        //      id:dt.id,
        //      file_url:dt.file_url,
        //      title : dt.title
        //    }
        //    self.formData.previewAssests.push(ImgData)
        //  })
        self.loading = false;
      };
      const failureHandler = function (res) {
        const data = res;
        console.log(data);
      };

      let formData = {};
      formData["campaign_id"] = this.campaignID;
    formData["lang"]=this.lang;
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    /**
     * to upload the image in new campaign */
    uploadImage() {
      let input = this.$refs.inputGallery;
      let file = input.files;
      // console.log(file, "files");
      this.formData.imgFile = file;

      // console.log(this.formData.imgFile, "multiple file");
      console.log(Object.keys(this.formData.imgFile).length, "multiple file");
      this.multipleFileLength = Object.keys(this.formData.imgFile).length;
      this.fileUploadCount = 0;
      for (const obj of this.formData.imgFile) {
        /**
         * handling multiple file upload
         */
        this.imgLoading = true;
        const self = this;
        const successHandler = function (res) {
          const data = res.data;
          let img = data.campaign_assests_detail;
          self.formData.previewAssests.push({
            id: img.id,
            file_url: img.file_url,
            title: img.title,
            type: img.type,
          });
          self.formData.imgIds.push(img.id);
          // console.log(self.formData.previewAssests)
          self.fileUploadCount = self.fileUploadCount + 1;
          if (self.fileUploadCount == self.multipleFileLength) {
            self.imgLoading = false;
          }
        };

        const failureHandler = function (res) {
          const data = res.data;
          console.log(data);
          self.imgLoading = false;
        };

        let formD = new FormData();

        formD.append("file", obj);
        formD.append("type", obj.type.split("/")[0]);
        Axios.request_POST(
          API_ADMIN_ADD_CAMPAIGN_IMAGE,
          formD,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },

    /**
     * to delete the images */
    deleteImage(id) {
      const self = this;
      // console.log(id);
      const delID = id;
      console.log(delID);
      const successHandler = function (res) {
        console.log(res.data);
        self.formData.previewAssests.splice(
          self.formData.previewAssests.findIndex((item) => item.id == delID),
          1
        );
      };
      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };
      let formJson = {};
      formJson["assests_id"] = delID;

      Axios.request_DELETE(
        API_ADMIN_DELETE_CAMPAIGN_IMAGE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    validate() {
      let validation = {
        school: this.formData.school,
        district: this.formData.district,
        district_name:this.district_name,
        host_name: this.formData.hostName,
        host_email: this.formData.hostEmail,
        graduation_date: this.formData.graduationDate,
        start_date: this.formData.eventStartDate,
        kick_off_date: this.formData.kickOffDate,
        gold_ball_give_away: this.formData.goldBallGiveawayDate,
        end_date: this.formData.eventEndDate,
        public_address: this.formData.subDomain,
      };
      this.CampaignDialogData.values = [];
      for (const [key, value] of Object.entries(validation)) {
        // console.log(`${key}:${value}`);
        if (value) {
          this.CampaignDialogData.values.push(key);
        }
      }
      this.CampaignDialogData.flag = true;
      this.CampaignDialogData.campaign_type = this.formData.campaign_type;
      this.formLoading = false;
    },
    postCallAxios() {
      const self = this;
      const successHandler = function (res) {
        console.log(res.data);
        self.formLoading = false;
        self.publishbtnLoader = false;
        self.saveDraftbtnLoader = false;
        self.$router.push(
          ROUTER_URL.adminPanel.children.campaignManagement.link
        );
        if (self.formData.publish) {
          self.showToast({
            message: "This Campaign Is Published Now",
            color: "s",
          });
        } else {
          self.showToast({
            message: "Campaign Is Saved As Draft",
            color: "s",
          });
        }
      };
      const failureHandler = function (res) {
        console.log("failure", res);
        self.formLoading = false;
        self.publishbtnLoader = false;
        self.saveDraftbtnLoader = false;
        self.showToast({
          message: res,
          color: "e",
        });
      };
      let form = {
        campaign_type: self.formData.campaign_type,
        school_id: self.formData.school,
       district_id: self.formData.district,
        payout_for: self.formData.payoutMode,
        sales_rep: self.formData.salesRep,
        event_support: self.formData.eventSupport,
        host_name: self.formData.hostName,
        host_email: self.formData.hostEmail,
        sub_domain: self.formData.subDomain,
        special_message: self.formData.SpecialMessage,
        district_goal: self.formData.districtGoal,
        school_default_goal: self.formData.schoolGoal,
        teacher_default_goal: self.formData.teacherGoal,
        student_default_goal: self.formData.studentGoal,
        // school_goal_description: self.schoolGoalDescription,
        teacher_incentive: self.formData.teacherIncentive,
        student_incentive: self.formData.studentIncentive,
        start_date: moment(self.formData.eventStartDate).format("MM-DD-YYYY"),
        kick_off_date: self.formatedKickOffDate,
        pre_registration_date: self.formatedPreregistrationDate,
        gold_ball_giveaway_date: self.formatedGoldBallDate,
        graduation_celebration_date: self.formatedGraduationDate,
        gold_ball_eblast_date: self.formatedEblastGoldBallDate,
        graduation_eblast_date: self.formatedEblastGraduationDate,
        end_date: self.formatedEndDate,
        is_published: self.formData.publish,
        // school_list: self.formData.schoolList,
        class_goal: self.classroomGoal,

        notes: self.notes,
      };
      if (self.pre_registration_start_time) {
        this.pre_registration_start_time = Helper.convertTime12To24(
          self.pre_registration_start_time
        );
      }
      if (self.pre_registration_end_time) {
        this.pre_registration_end_time = Helper.convertTime12To24(
          self.pre_registration_end_time
        );
      }
      if (self.pre_registration_presence) {
        this.pre_registration_presence = self.pre_registration_presence;
      }
      if (self.pre_registration_team) {
        this.pre_registration_team = self.pre_registration_team;
      }
      if (self.start_date_start_time) {
        this.start_date_start_time = Helper.convertTime12To24(
          self.start_date_start_time
        );
      }
      if (self.start_date_end_time) {
        this.start_date_end_time = Helper.convertTime12To24(
          self.start_date_end_time
        );
      }
      if (self.kick_off_start_time) {
        this.kick_off_start_time = Helper.convertTime12To24(
          self.kick_off_start_time
        );
      }
      if (self.kick_off_end_time) {
        this.kick_off_end_time = Helper.convertTime12To24(
          self.kick_off_end_time
        );
      }
      if (self.kick_off_presence) {
        this.kick_off_presence = self.kick_off_presence;
      }
      if (self.kick_off_team) {
        this.kick_off_team = self.kick_off_team;
      }
      if (self.gold_ball_start_time) {
        this.gold_ball_start_time = Helper.convertTime12To24(
          self.gold_ball_start_time
        );
      }
      if (self.gold_ball_end_time) {
        this.gold_ball_end_time = Helper.convertTime12To24(
          self.gold_ball_end_time
        );
      }
      if (self.gold_ball_presence) {
        this.gold_ball_presence = self.gold_ball_presence;
      }
      if (self.graduation_start_time) {
        this.graduation_start_time = Helper.convertTime12To24(
          self.graduation_start_time
        );
      }
      if (self.graduation_end_time) {
        this.graduation_end_time = Helper.convertTime12To24(
          self.graduation_end_time
        );
      }
      if (self.graduation_presence) {
        this.graduation_presence = self.graduation_presence;
      }

      if (self.formData.previewAssests)
        form["assests_list"] = self.formData.previewAssests;

    
      for (var propName in form) {
        if (
          form[propName] === null ||
          form[propName] === "undefined" ||
          form[propName] === "" ||
          form[propName] === "Invalid date" ||
          form[propName] == 0
        ) {
          delete form[propName];
        }
      }

      this.kickoffplayerCopy = [];
      Object.keys(this.kick_off_players).forEach((key) =>
        this.kickoffplayerCopy.push(this.kick_off_players[key].id)
      );
      if (this.kick_off_team) {
        form["kick_off_team"] = this.kick_off_team;
        form["kick_off_players"] = [];
      } else {
        form["kick_off_team"] = null;
        form["kick_off_players"] = this.kickoffplayerCopy;
      }
      this.preregistrationplayreCopy = [];
      Object.keys(this.pre_registration_players).forEach((key) =>
        this.preregistrationplayreCopy.push(
          this.pre_registration_players[key].id
        )
      );

      if (this.pre_registration_team) {
        form["pre_registration_team"] = this.pre_registration_team;
        form["pre_registration_players"] = [];
      } else {
        form["pre_registration_team"] = null;
        form["pre_registration_players"] = this.preregistrationplayreCopy;
      }
      this.graduationplayerCopy = [];
      Object.keys(this.graduation_players).forEach((key) =>
        this.graduationplayerCopy.push(this.graduation_players[key].id)
      );
      if (this.graduation_team) {
        form["graduation_team"] = this.graduation_team;
        form["graduation_players"] = [];
      } else {
        form["graduation_team"] = null;
        form["graduation_players"] = this.graduationplayerCopy;
      }

      this.goldballplayerCopy = [];
      Object.keys(this.gold_ball_players).forEach((key) =>
        this.goldballplayerCopy.push(this.gold_ball_players[key].id)
      );
      if (this.gold_ball_team) {
        form["gold_ball_team"] = this.gold_ball_team;
        form["gold_ball_players"] = [];
      } else {
        form["gold_ball_team"] = null;
        form["gold_ball_players"] = this.goldballplayerCopy;
      }
      form["notes"] = this.notes;
      if (this.pre_registration_start_time) {
        form.pre_registration_start_time = this.pre_registration_start_time;
      }
      if (this.pre_registration_end_time) {
        form.pre_registration_end_time = this.pre_registration_end_time;
      }
      if (this.pre_registration_presence) {
        form.pre_registration_presence = this.pre_registration_presence;
      }
      if (this.pre_registration_team) {
        form.pre_registration_team = this.pre_registration_team;
      }
      if (this.start_date_start_time) {
        form.start_date_start_time = this.start_date_start_time;
      }
      if (this.start_date_end_time) {
        form.start_date_end_time = this.start_date_end_time;
      }
      if (this.kick_off_start_time) {
        form.kick_off_start_time = this.kick_off_start_time;
      }
      if (this.kick_off_end_time) {
        form.kick_off_end_time = this.kick_off_end_time;
      }

      if (this.kick_off_presence) {
        form.kick_off_presence = this.kick_off_presence;
      }
      if (this.kick_off_team) {
        form.kick_off_team = this.kick_off_team;
      }
      if (this.additional_kickoff_assemblies) {
        form.additional_kickoff_assemblies = this.additional_kickoff_assemblies;
      }
      if (this.gold_ball_start_time) {
        form.gold_ball_start_time = this.gold_ball_start_time;
      }
      if (this.gold_ball_end_time) {
        form.gold_ball_end_time = this.gold_ball_end_time;
      }
      if (this.gold_ball_presence) {
        form.gold_ball_presence = this.gold_ball_presence;
      }
      if (this.graduation_start_time) {
        form.graduation_start_time = this.graduation_start_time;
      }
      if (this.graduation_end_time) {
        form.graduation_end_time = this.graduation_end_time;
      }
      if (this.graduation_presence) {
        form.graduation_presence = this.graduation_presence;
      }
      if (this.additional_graduation_assemblies) {
        form.additional_graduation_assemblies =
          this.additional_graduation_assemblies;
      }
      if (self.type === "edit") {
        form["campaign_id"] = this.campaignID;
      }
      form["lang"]=this.lang;
      if (this.type === "edit") {
        console.log(self.type);
        Axios.request_PATCH(
          API_ADMIN_CAMPAIGN_MANAGEMENT_UPDATE,
          form,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        Axios.request_POST(
          API_ADMIN_CAMPAIGN_MANAGEMENT_POST,
          form,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    setkickoffplayers(playerList) {
      const self = this;
      if (this.kick_off_players[0]) {
        let res = [];
        res = playerList.filter((el) => {
          return !self.filterList.find((element) => {
            return element.id === el.id;
          });
        });
        self.kick_off_players = self.kick_off_players.concat(res);
      } else {
        this.kick_off_players = playerList;
        this.filterList = playerList;
      }
    },
    setgoldballplayers(playerList) {
      const self = this;
      if (this.gold_ball_players[0]) {
        let res = [];
        res = playerList.filter((el) => {
          return !self.filterList.find((element) => {
            return element.id === el.id;
          });
        });
        self.gold_ball_players = self.gold_ball_players.concat(res);
      } else {
        this.gold_ball_players = playerList;
        this.filterList = playerList;
      }
    },
    setgraduationplayers(playerList) {
      const self = this;
      if (this.graduation_players[0]) {
        let res = [];
        res = playerList.filter((el) => {
          return !self.filterList.find((element) => {
            return element.id === el.id;
          });
        });
        self.graduation_players = self.graduation_players.concat(res);
      } else {
        this.graduation_players = playerList;
        this.filterList = playerList;
      }
    },
    submitForm(key) {
      if (this.$refs.newCampaignForm.validate()) {
        const self = this;
        self.formLoading = true;

        if (key) {
          if (
            (this.formData.school || this.formData.district) &&
            this.formData.hostName &&
            this.formData.hostEmail &&
            this.formData.eventStartDate &&
            this.formData.subDomain
          ) {
            this.formData.publish = true;
            this.publishbtnLoader = true;

            this.postCallAxios();
          } else {
            this.validate();
          }
        } else {
          this.postCallAxios();
          this.saveDraftbtnLoader = true;
        }
      }
    },
    getteamPlayer() {
      const successHandler = (res) => {
        this.playerList = res.data.player_list;
        //this.setkickoffplayers(res.data.kick_off_player_list);
        // this.setgoldballplayers(res.data.gold_ball_player_list);
        // this.setgraduationplayers(res.data.graduation_player_list);
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {};
      let formData = {};
      if (this.team_name) {
        formData["team_name"] = this.team_name;
      }
      Axios.request_GET(
        API_EVENT_TEAM_PLAYER_LIST_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getTeamNameList() {
      console.log("!!!!!!!!!!!");
      const self = this;
      self.loading = true;
      console.log("!!!!!!!!!!!$$$");
      const successHandler = (res) => {
        const teamnaeee = res.data.team_name_list;
        const teamlistsss = [{ id: null, team_name: "Custom" }];
        self.teamNameList = teamlistsss.concat(teamnaeee);
        console.log("self.teamNameList", self.teamNameList);
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formData = {};
      return Axios.request_GET(
        API_ADMIN_TEAM_NAME_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    /*** Handles setting form data if present in route params ***/
    setFormDataFromParam() {
      if (this.type === "add") {
        if (this.$route.params[CAMPAIGN_TYPE]) {
          this.formData[CAMPAIGN_TYPE] = this.$route.params[CAMPAIGN_TYPE];
          this.formData[this.formData[CAMPAIGN_TYPE]] = this.$route.params[ID];
          this.formData.salesRep = this.$route.params[SALES_REP];
          this.formData.eventSupport = this.$route.params[EVENT_SUPPORT];
          this.formData.hostName = this.$route.params[HOST_NAME];
          this.formData.hostEmail = this.$route.params[HOST_EMAIL];
           this.formData.district = this.$route.params[DISTRICT];
           this.formData.district_name = this.$route.params[DISTRICT_NAME];
        }
      }
    },
    /**
     * District autofill */
    getAutoFillDistrictDetails() {
      const self = this;
      const successHandler = (res) => {
        let data = res.data;
        // this.previewImage = null;
        if (this.type === "add") {
          this.formData.salesRep = data.district_detail.sales_rep;
        }
        this.formData.eventSupport = data.district_detail.event_support;
        this.formData.hostName = data.district_detail.user_name;
        this.formData.hostEmail = data.district_detail.user_email;
      };
      const failureHandler = (res) => {
        console.log("Failure ", res);
      };
      const finallyHandler = function () {
        if (self.type === "add") {
          self.getSubDomain();
        }
      };
      let formData = {};
      formData["district_id"] = this.formData.district;

      Axios.request_GET(
        API_ADMIN_GET_DISTRICT_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getAutoFillSchoolDetails() {
      const self = this;
      const successHandler = (res) => {
        let data = res.data;
        // this.previewImage = null;
        if (this.type === "add") {
          this.formData.salesRep = data.school_detail.sales_rep;
        }
        this.formData.eventSupport = data.school_detail.event_support;
        this.formData.hostName = data.school_detail.user_name;
        this.formData.hostEmail = data.school_detail.user_email;
        this.formData.studentIncentive =
          data.school_detail.raising_money_purpose;
           this.formData.district = data.school_detail.district;
      };
      const failureHandler = (res) => {
        console.log("Failure ", res);
      };
      const finallyHandler = function () {
        if (self.type === "add") {
          self.getSubDomain();
        }
      };
      let formData = {};
      formData["school_id"] = this.formData.school;
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSubDomain() {
      const successHandler = (res) => {
        let data = res.data;
        console.log(data, "subdomain");
        // this.previewImage = null;
        this.formData.subDomain = data.sub_domain;
        this.domainCheckOff = true;
      };
      const failureHandler = (res) => {
        console.log("Failure ", res);
      };
      let formData = {};
      let domain_type = "school_id";
      formData[domain_type] = this.formData["school"];
      Axios.request_GET(
        API_ADMIN_GET_SUBDOMAIN,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getValidateSubDomain() {
      const successHandler = (res) => {
        let data = res.data;
        if (data.success) {
          this.domainCheckOff = true;
        } else {
          this.domainCheckOff = false;
        }
      };
      const failureHandler = (res) => {
        console.log("Failure ", res);
        this.domainCheckOff = false;
      };
      let formData = {};
      if (this.formData.subDomain) {
        formData["sub_domain"] = this.formData.subDomain;
      }
      Axios.request_GET(
        API_ADMIN_GET_VALIDATE_SUBDOMAIN,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.loading = true;
    this.type = this.$route.query.type;
    this.campaignID = this.$route.query.id;
    this.getSchoolList();
    this.setFormDataFromParam();
  },
};
</script>
<style scoped>
.form-heading {
  border-bottom: 2px solid #2c1963;
  color: #2c1963;
  width: 230px;
  height: 30px;
  font-size: 20px;
  font-weight: 500;
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.v-text-field__slot > .v-label {
  pointer-events: none;
}
.bulk {
  font-family: Lato;
  color: #2c1963;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  border-bottom: 2px solid #a7a7a7;
  float: right;
  position: relative;
  bottom: 28px;
}
.col.input {
  height: 70px;
}
.quill-editor {
  height: auto;
  overflow: visible;
}

.quill-editor >>> div {
  left: 0px !important;
  overflow: visible !important;
}
.gallery {
  width: 100%;
  height: 187px;
  border: 1.5px dotted #2c1963;
  text-align: center;
  font-family: Lato;
  border-radius: 5px;
}
.input-label {
  margin-top: 18%;
}
.previewImages {
  background-color: linear-gradient(#333333 100% #c4c4c4 0%);
}
.delete-btn {
  background-color: white;
  cursor: pointer;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 5px;
  padding: 2px;
}
.footer-sbt {
  width: 100%;
  height: 80px;
  background-color: #e7e5ed;
}
.v-btn {
  font-family: Lato;
  float: right;
  margin-right: 20px;
  margin-top: 22px;
}
.v-btn.p-btn {
  color: white;
  background-color: #38227a;
  margin-right: 45px;
}
.v-btn.s-btn {
  color: #38227a;
  border: 0.5px solid #38227a;
  background-color: white;
}
.v-input {
  font-family: Lato;
}
@media only screen and (max-width: 600px) {
  .bulk {
    float: left;
    position: static;
    margin-top: 10px;
  }
}
</style>
