<template>
  <v-container>
    <v-card class="pa-5">
      <v-card-title
        style="font-size: 29px; font-weight: 600; justify-content: center"
      >
        Terms and Conditions
      </v-card-title>
      <v-card-text>
        <p style="font-size: 15px;">
          Welcome to Harlem Wizards. These terms and conditions outline the
          rules and regulations for the use of the Harlem Wizards website and
          app, owned and operated by Harlem Wizards. By accessing and using this
          website or app, you agree to comply with and be bound by the following
          terms and conditions.
        </p>

        <h3>1. Acceptance of Terms</h3>
        <p style="font-size: 15px;">
          By accessing or using the Harlem Wizards website or app, you agree to
          be bound by these terms and conditions, and our Privacy Policy. If you
          disagree with any part of the terms, you must discontinue your use of
          the site and services.
        </p>

        <h3>2. User Accounts and Registration</h3>
        <p style="font-size: 15px;">
          To access certain features of the Harlem Wizards, you may need to
          create an account. You agree to provide accurate, current, and
          complete information during the registration process and update such
          information to keep it accurate and complete.
        </p>
        <p style="font-size: 15px;">
          You are responsible for maintaining the confidentiality of your
          account login information and all activities under your account.
          Harlem Wizards reserves the right to terminate your account for any
          breach of these terms.
        </p>

        <h3>3. Use of Services</h3>
        <p style="font-size: 15px;">
          You agree to use Harlem Wizards only for lawful purposes. You must
          not:
        </p>
        <ul>
          <li>Use the site or app for any illegal or unauthorized purpose.</li>
          <li>
            Access, tamper with, or use non-public areas of the site or app
            without authorization.
          </li>
          <li>
            Transmit any worms, viruses, or any code of a destructive nature.
          </li>
        </ul>
        <p>
          Any violation may result in the suspension or termination of your
          account.
        </p>

        <h3>4. Intellectual Property</h3>
        <p style="font-size: 15px;">
          All content, including but not limited to text, graphics, logos,
          images, and software on Harlem Wizards, is the property of Harlem
          Wizards and is protected by intellectual property laws. You may not
          use, copy, reproduce, or distribute any of this content without
          express written consent from Harlem Wizards.
        </p>

        <h3>5. Third-Party Links</h3>
        <p style="font-size: 15px;">
          Harlem Wizards may contain links to third-party websites or services
          that are not owned or controlled by Harlem Wizards. We are not
          responsible for the content, policies, or practices of any third-party
          websites.
        </p>

        <h3>6. Disclaimer of Warranties</h3>
        <p style="font-size: 15px;">
          Your use of Harlem Wizards is at your sole risk. The site and app are
          provided "as is" and "as available," without warranty of any kind.
          Harlem Wizards makes no warranties, express or implied, and hereby
          disclaims and negates all other warranties, including without
          limitation, implied warranties or conditions of merchantability,
          fitness for a particular purpose, or non-infringement of intellectual
          property or other violation of rights.
        </p>

        <h3>7. Limitation of Liability</h3>
        <p style="font-size: 15px;">
          In no event shall Harlem Wizards be liable for any damages (including,
          without limitation, damages for loss of data, profit, or due to
          business interruption) arising out of the use or inability to use the
          materials on Harlem Wizards, even if Harlem Wizards has been notified
          orally or in writing of the possibility of such damage.
        </p>

        <h3>8. Indemnification</h3>
        <p style="font-size: 15px;">
          You agree to indemnify and hold harmless Harlem Wizards, its
          affiliates, employees, and partners from any claim, demand, or damage,
          including reasonable attorneys' fees, arising out of your use of the
          website or app, your violation of these terms, or your violation of
          any rights of another.
        </p>

        <h3>9. Termination</h3>
        <p style="font-size: 15px;">
          Harlem Wizards reserves the right to suspend or terminate your access
          to Harlem Wizards at any time, without notice, for conduct that Harlem
          Wizards believes violates these Terms, violates applicable law, or is
          harmful to other users or the business interests of Harlem Wizards.
        </p>

        <h3>10. Governing Law</h3>
        <p style="font-size: 15px;">
          These Terms are governed by and construed in accordance with the laws
          of NJ, USA, and you irrevocably submit to the exclusive jurisdiction
          of the courts in that State or location.
        </p>

        <h3>11. Changes to Terms</h3>
        <p style="font-size: 15px;">
          Harlem Wizards reserves the right to modify these terms at any time.
          Any changes will be posted on this page and become effective upon
          posting. Your continued use of the website or app constitutes your
          acceptance of any such modifications.
        </p>

        <h3>12. Contact Information</h3>
        <p style="font-size: 15px;">
          If you have any questions about these Terms and Conditions, please
          contact us at:
        </p>
        <p>Email: support@harlemwizardsinabox.com</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped>
h3 {
  font-weight: bold;
  margin-top: 1rem;
}
p,
ul {
  margin: 0.5rem 0;
}
</style>
